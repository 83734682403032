<template>
  <div>
    <Title :TitleName="TitleName"></Title>
    <div class="user_container">
      <div class="user_inside">
        <div class="user_inside_img">
          <img src="../../../assets/avatar_default.png" />
        </div>
        <div class="user_inside_info">
          <div>{{ i18n.name }}：{{ info.fullName }}</div>
          <div>{{ i18n.Membernumber }}：{{ info.distNo }}</div>
          <div>{{ i18n.AchievementTitle }}：{{ info.pin }}</div>
          <div class="qr_code">
            <img :src="qrcode" alt="">
          </div>
        </div>
      </div>
      <div class="user-info">
        <div class="user-info-inside">
          {{ i18n.Gender }}：{{ info.gender == 1 ? "男" : ""
          }}{{ info.gender == 2 ? "女" : "" }}
        </div>
        <div class="user-info-inside">
          {{ i18n.Birthday }}：{{ info.birthday }}
        </div>
        <div class="user-info-inside">
          {{ i18n.ChineseName }}：{{ info.fullName }}
        </div>
        <div class="user-info-inside">
          {{ i18n.Registrationarea }}：{{ info.country }}
        </div>
        <div class="user-info-inside">
          {{ i18n.Mobilenumber }}：{{ info.mobile }}
        </div>
        <div class="user-info-inside">{{ i18n.Email }}：{{ info.email }}</div>
        <div class="user-info-inside">
          {{ i18n.Introducernumber }}：{{ info.sponsorDistNo }}
        </div>
        <div class="user-info-inside">{{ i18n.Introducer }}：{{info.sponsorName}}</div>
        <!-- <div class="user-info-inside">
          {{ i18n.notification }}：{{
            info.noticeOfAcceptance == 0 ? "Email" : "SMS"
          }}
        </div> -->
      </div>

      
      <div class="btn">
        <el-button @click="tuichu" type="danger">{{ i18n.account }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { removeLocalStorage } from "@/utils/local-storage";
import { AuthLogout } from "../../../api/index.js";
import Title from "../../../components/title.vue";
export default {
  computed: {
    i18n() {
      return this.$t("index");
    },
  },
  props: {
    info: {
      type: Object,
    },
    qrcode: {
      type: String,
    },
  },
  components: {
    Title,
  },
  data() {
    return {
      TitleName: "帳戶資料",
      centerDialogVisible: false,
    };
  },
  methods: {
    tuichu() {
      AuthLogout().then((res) => {
        removeLocalStorage("Authorization");
        removeLocalStorage("avatar");
        removeLocalStorage("nickName");
        removeLocalStorage("pin");
        removeLocalStorage("distNo");
        if (res.errno == 0) {
          this.$router.push({
            path: "/Login",
          });
        }
      });
    },
    UploadAvatar() {
      this.centerDialogVisible = true;
    },
  },
};
</script>

<style lang="scss">
.user_container {
  width: 93%;
  height: 800px;
  //background-color: red;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;

  .user_inside {
    width: 100%;
    height: 131px;
    //background-color: yellow;
    border-bottom: 1px solid #dadada;
    display: flex;
    padding-bottom: 10px;
    align-items: center;
    position: relative;

    .user_inside_img {
      width: 70px;
      height: 80px;

      //background-color: pink;
      img {
        width: 100%;
        height: 100%;
      }
    }

    // .user_inside_name {
    // 	width: 100px;
    // 	height: 100px;
    // 	//background-color: purple;
    // 	margin-left: 30px;
    // 	display: flex;
    // 	flex-direction: column;
    // 	justify-content: center;
    // 	align-items: center;

    // 	h3 {
    // 		font-size: 18px;
    // 		font-weight: 400;
    // 		color: #333;
    // 		margin-bottom: 5px;
    // 	}

    // 	button {
    // 		border: 1px solid #5c81e3;
    // 		border-radius: 4px;
    // 		font-size: 12px;
    // 		color: #fff;
    // 		background-color: #678ee7;
    // 		height: 30px;
    // 		width: 100px;
    // 		padding: 0px;
    // 	}
    // }
    .user_inside_info {
      width: 300px;
      height: 100%;
      //background-color: red;
      margin-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .qr_code {
        // background-color: red;
        position: absolute;
        right: 15%;
        top: 10px;
        width: 125px;
        height: 125px;
        img {
          width: 100%;
          // height: 100%;
        }
      }
    }
  }
  .user-info {
    margin-top: 20px;
    width: 95%;
    height: 500px;
    //background-color: yellow;
    padding: 20px;
    .user-info-inside {
      width: 100%;
      height: 55px;
      border-bottom: 1px solid #fda98b;
      display: flex;
      align-items: center;
    }
  }
  .btn {
    width: 100%;
    display: flex;
    justify-content: center;
    .el-button {
      border-color: #f56c6c;
      background-color: white;
      color: black;
    }
    .el-button:hover {
      border-color: #f56c6c;
      background-color: #f56c6c;
      color: white;
    }
  }
}
.dialog_container {
  width: 100%;
  height: 300px;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  .left {
    width: 30%;
    height: 100%;
    //background-color: red;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img {
      width: 100px;
      height: 100px;
      border: 1px solid rgb(204, 204, 204);
      img {
        width: 100%;
        height: 100%;
      }
    }
    p {
      color: #666;
      font-size: 14px;
    }
    div {
      button {
        color: #666;
        width: 80px;
        height: 30px;
        padding: 0px;
      }
    }
  }
  .right {
    width: 220px;
    height: 220px;
    margin-left: 20px;
    border: 1px solid rgb(204, 204, 204);
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
}
.el-dialog__header {
  border-bottom: 1px solid #e6e6e6;
}
</style>
