<!--
 * @Author: Zzy
 * @Date: 2021-07-19 10:10:28
 * @LastEditTime: 2021-08-04 18:24:12
 * @LastEditors: Zzy
 * @Description: 
 * @FilePath: /hfs-mall-pc/src/views/paySuc/paySuc.vue
 * Creat by Zzy
-->
<template>
  <div class="paySuc-container">
    <div class="header">
      <div class="imgae">
        <img src="../../assets/群蜂支付成功.png" />
      </div>
      <div class="text">支付成功</div>
      <div class="btn">
        <el-button @click="jump">返回首頁</el-button>
        <el-button @click="jump2">繼續選購商品</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { PaySuc } from "../../api/index.js";
export default {
  created() {
    PaySuc({
      paymentId: this.$route.query.paymentId,
      PayerID: this.$route.query.PayerID,
      token: this.$route.query.token,
    }).then((res) => {
      console.log(res);
      console.log("这是支付成功的接口" + res);
    });
  },
  data() {
    return {};
  },
  methods: {
    jump() {
      this.$router.push({
        path: "/",
      });
    },
    jump2() {
      this.$router.push({
        path: "/Detail",
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../common/style";
.paySuc-container {
  padding-top: 100px;
  width: 99vw;
  height: 400px;
  //background-color: red;
  .header {
    width: 100%;
    height: 350px;
    //background-color: blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .imgae {
      width: 100px;
      height: 100px;
      //background-color: yellow;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      color: $neirongColor;
      font-size: 20px;
      margin-top: 20px;
    }
    .btn {
      width: 30%;
      height: 100px;
      //background-color: green;
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-button {
        border: 1px solid $neirongColor;
        color: black;
        background-color: white;
        width: 130px;
      }

      .el-button:hover {
        border: 1px solid $neirongColor;
        color: white;
        background-color: $neirongColor;
      }
    }
  }
}
</style>
