export default {
	index: {
		//header.vue
		homePage: 'Home page',
		Pleaseentertheproductname: 'Please enter the product name',
		Allproducts: 'All Products',
		ShoppingCart: 'Shopping Cart',
		personalinformation: 'Personal profile',
		Contact: 'Contact Us',
		Chooselanguage: 'Choose language',
		Pleaseenterthecontent: 'Please enter the content',

		//home.vue
		Popularrecommendation: 'Best seling products',
		Newproductlaunch: 'New product',

		//foot.vue
		Focusonus: 'Focus on us',
		Aboutus: 'About us',
		Companyinformation: 'Company information',
		Termsofuse: 'Terms of use',
		Privacypolicy: 'Privacy policy',
		Informationinquiry: 'Information inquiry',
		Frequentlyaskedquestions: 'Frequently asked questions',
		Aboutdelivery: 'About delivery',
		Aboutreturns: 'About returns',
		Ordertracking: 'Order tracking',

		//Detail.vue
		Noproductsonsale: 'No products on sale',
		purchase: 'purchase',

		//List.vue
		number: 'Quantity',
		addtocart: 'Add to cart',
		Buynow: 'Buy now',
		productdetails: 'Product detail',
		Nodetails: 'No details',
		Relatedproducts: 'Related products',
		Addedtocart: 'Added to cart',

		//placeOrder.vue
		Confirmclosing: 'Confirm order',
		Totalcommodities: 'Total amount',
		Freight: 'Freight charge',
		Taxation: 'Taxation',
		Preferentialamount: 'Preferential amount',
		Ordernotes: 'Order remark',
		Pleaseinputthecontent: 'Please input the content',
		Orderamount: 'Order amount',
		Actualamountpayable: 'Actual amount payable',
		Payimmediately: 'Pay immediately',
		possible: 'There is no delivery address for the moment. Please add it as soon as possible',
		Receivinginformation: 'Delivery information',
		name: 'Name',
		contactnumber: 'Contact number',
		Detailedaddress: 'Detailed address',
		Changeaddress: 'Change address',
		first: 'No product selected, please select product first',
		address: 'Please set the receiving address',

		//payment.vue
		Ordernumber: 'Order number',
		Amountactuallypaid: 'Amount actually paid',
		Submit: 'Confirm',

		//Cart.vue
		Selectall: 'Select all',
		Shoppinglist: 'Shopping list',
		Yourshoppingcartisempty: 'Your shopping cart is empty',
		Productinformation: 'Product information',
		Unitprice: 'Unit price',
		operation: 'operation',
		Total: 'Total',
		settlement: 'settlement',
		anyproducts: 'No product is chosen',

		//payFail.vue
		Paymentfailure: 'Payment failure',
		Returnorder: 'Return order',

		//title.vue
		Addshippingaddress: 'Delivery address',

		//userinfo.vue
		Myaccount: 'Personal profile',
		Accountinformation: 'Personal information',
		Myorder: 'My order',
		Receivingaddress: 'Delivery address',

		//AccountInformation.vue
		Membernumber: 'Member number',
		AchievementTitle: 'Achievement Title',
		Gender: 'Gender',
		Birthday: 'Birthday',
		ChineseName: 'Chinese Name',
		Registrationarea: 'Registration area',
		Mobilenumber: 'Mobile number',
		arearnumber: 'Global roaming',
		Email: 'Email',
		Introducernumber: 'Sponsor number',
		Introducer: 'Sponsor',
		notification: 'Way to receive notification',
		account: 'Exit the current account',

		//myOrder.vue
		date: 'Date',
		state: 'State',


		//oderDetail.vue
		usecode: 'Use discount code',
		Voucher: 'Using e-shopping Voucher',
		deleteorder: "Delete order",
		cancelorder: 'Cancel order',
		pay: 'Pay',
		Deleteorder: 'Delete this order',
		Tips: 'Tips',
		Confirm: 'Confirm',
		cancel: 'Cancel',
		Successfullydeleted: "Successfully deleted",
		Deletioncanceled: 'Deletion canceled',
		Doyouwanttocancelorder: 'Do you want to cancel this order',
		Cancelsuccessfully: 'Cancel successfully',
		Cancelled: 'Cancelled',

		//HarvestAddress.vue
		Telephone: 'Telephone',
		addressyet: 'You no add an address',
		Addaddress: 'Add address',
		modify: 'Modify',
		Delete: 'Delete',
		Usethisaddress: 'Use this address',
		Newreceivingaddress: 'New delivery address',
		ConsigneeName: 'Recipient name',
		Pleaseregion: 'Please select a region',
		PleaseselectProvince: 'Please select Province',
		Pleaseselectarea: 'Please select area',
		preservation: "Confirm",
		Editshippingaddress: 'Edit shipping address',
		Pleasephonnumber: 'Please enter the correct phone number!',
		Addaddresssuccessfully: 'Add address successfully',
		Addressmodifiedsuccessfully: 'Address modified successfully',

		//login.vue
		Logaccount: 'Account login',
		Accountnumber: 'Account number',
		password: 'Password',
		Registeredaccountnumber: 'Account registration',
		Forgetthepassword: 'Forget  password',
		login: 'Login',
		Return: 'Return',

		//forget.vue
		Findthepassword: 'Find the password',
		Verificationcode: 'Verification code',
		Sendverificationcode: 'Send Email verification code',
		Sendmobilecode: 'Send SMS verification code',
		Verificationcodesentsuccessfully: 'Verification code sent successfully',
		SMS: 'Password modified successfully',
		blank: 'Please check if there is any blank',
		UseEmail: 'Email verification',
		UseSMS: 'SMS verification',
		newPassword: 'New password',

		//register.vue
		newaccount: 'Sign up for a new account',
		Sponsorsname: 'Sponsor name',
		ChineseEnglishsurname: 'Chinese / English surname',
		ChineseEnglishname: 'Chinese / English name',
		Pleaseselectactivearea: 'Please select active area',
		male: 'male',
		female: 'female',
		Selectadate: 'Select date',
		register: 'Register',
		mobilephonenumber: 'Please input mobile phone number',
		registrationarea: 'Please enter the registration area',
		loginwassuccessful: 'login was successful',
		yourmobilephone: 'The password has been sent to your mobile phone',
		isanyblank: 'Please check if there is any blank'
	}
}