<template>
  <div class="cart_container">
    <div class="cart_inside">
      <div class="title">{{ i18n.Shoppinglist }}</div>

      <div class="shop_list_null" v-if="shopList.length == 0">
        <div class="img">
          <img src="../../assets/购物车.png" />
        </div>
        <p>{{ i18n.Yourshoppingcartisempty }}</p>
        <el-button @click="$router.push({ path: '/Detail' })">{{
          i18n.Buynow
        }}</el-button>
      </div>

      <div v-else>
        <div class="cart-table-title">
          <span class="shop_info">{{ i18n.Productinformation }}</span>
          <div class="xiangqing">
            <span>{{ i18n.Unitprice }}</span>
            <span>{{ i18n.number }}</span>
            <!-- <span>BV</span> -->
            <span>{{ i18n.operation }}</span>
          </div>
        </div>

        <el-checkbox-group @change="changeCheck" v-model="productIds">
          <div
            class="cart-table-middle"
            v-for="(item, index) in shopList"
            :key="index"
          >
            <div class="left">
              <el-checkbox
                class="shiyan"
                :label="item.productId"
                :checked="item.checked"
              >
                {{ item.goodsName }}
              </el-checkbox>
              <div class="img">
                <img :src="item.picUrl" />
              </div>
              <!-- <div class="shop-name">{{item.goodsName}}</div> -->
            </div>
            <div class="right">
              <span class="price">HK${{ item.price }}</span>
              <el-input-number
                @change="selectNum($event, item)"
                v-model="item.number"
                :min="1"
                :max="100000000"
                label="描述文字"
              >
              </el-input-number>
              <!-- <span class="Subtotal">¥ {{item.bv}}</span> -->
              <el-button
                @click="oneDelete(item.productId)"
                plain
                icon="el-icon-delete"
              ></el-button>
            </div>
          </div>
        </el-checkbox-group>

        <div class="cart-table-bottom">
          <div class="cart-table-bottom-left">
            <el-checkbox @change="allChange" v-model="allCheckedStatus">{{
              i18n.Selectall
            }}</el-checkbox>
          </div>
          <div class="cart-table-bottom-right">
            <div class="Amount-payable">
              <span class="choose">{{ i18n.Total }}：</span>
              <span class="choose-num">HK${{ retailPrice }}</span>
              <!-- <p class="all">BV：{{BVnum}}</p> -->
            </div>
            <el-button type="primary" @click="send">{{
              i18n.settlement
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <getGood @buy="buys" :title="title" :GoodList="GoodList"></getGood>
    <Foot></Foot>
  </div>
</template>

<script>
import Foot from "../../components/foot.vue";
import getGood from "../../components/getGoods.vue";
import { setLocalStorage } from "@/utils/local-storage";
import {
  CartList,
  cartUpdate,
  CartChecked,
  CartDelete,
  GetGoods,
} from "../../api/index.js";
export default {
  data() {
    return {
      shopList: [],
      BVnum: 0,
      retailPrice: 0,
      productIds: [],
      allCheckedStatus: false,
      GoodList: [],
      title: "相關產品",
    };
  },
  computed: {
    i18n() {
      return this.$t("index");
    },
  },
  components: {
    getGood,
    Foot,
  },
  created() {
    CartList().then((res) => {
      //渲染购物车数据至页面的接口
      console.log(res);
      if (res.errno == 0) {
        this.shopList = res.data.cartList;
        this.BVnum = res.data.cartTotal.bvAmout;
        this.retailPrice = res.data.cartTotal.checkedGoodsAmount;
        console.log(this.shopList);
        this.allCheckedStatus = this.shopList.every((item) => item.checked);
        console.log(this.allCheckedStatus);
      } else if (res.errmsg == "請登錄") {
        this.$notify.error({
          title: res.errmsg,
        });
        setTimeout(() => {
          this.$router.push({
            path: "/Login",
          });
        }, 2500);
      } else {
        this.$notify.error({
          title: res.errmsg,
        });
      }
    });
    GetGoods().then((res) => {
      this.GoodList = res.data;
      console.log(this.GoodList);
    });
  },
  methods: {
    buys(id) {
      let routeUrl = this.$router.resolve({
        path: "/List",
        query: {
          id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    send() {
      //结算按钮
      if (this.retailPrice == 0) {
        this.$notify.error({
          title: this.i18n.anyproducts,
        });
      } else {
        setLocalStorage({
          AddressId: 0,
          CartId: 0,
        });
        this.$router.push({
          path: "/placeOrder",
        });
      }
    },
    oneDelete(id) {
      //删除某个商品的按钮
      let arry = [];
      arry.push(id);
      CartDelete({
        productIds: arry,
      }).then((res) => {
        this.shopList = res.data.cartList;
        this.BVnum = res.data.cartTotal.bvAmout;
        this.retailPrice = res.data.cartTotal.checkedGoodsAmount;
        console.log(this.shopList);
        this.allCheckedStatus = this.shopList.every((item) => item.checked);
      });
    },
    allChange() {
      //全选的逻辑
      this.shopList.forEach((item) => {
        this.productIds.push(item.productId);
      });
      this.productIds = this.allCheckedStatus ? this.productIds : [];
      let arr = this.shopList.map((item) => item.productId);
      CartChecked({
        idsNotList: arr,
        productIds: this.productIds,
      }).then((res) => {
        console.log(res);
        this.BVnum = res.data.cartTotal.bvAmout;
        this.retailPrice = res.data.cartTotal.checkedGoodsAmount;
      });
    },
    selectNum(e, item) {
      //商品数量变化的接口
      let parmes = {
        number: e,
        goodsId: item.goodsId,
        id: item.id,
        productId: item.productId,
      };
      console.log(e);
      cartUpdate(parmes).then((res) => {
        console.log(res);
        this.BVnum = res.data.bv;
        this.retailPrice = res.data.retailPrice;
      });
    },
    changeCheck(e) {
      //选中商品的接口
      let all = []; //全部的商品ID
      all = this.shopList.map((item) => item.productId);
      console.log(all);
      let noproductId = all.filter((item) => e.indexOf(item) == -1); //拿e数组和all数组比较，把两个数组中不相同的数据挑出来
      console.log(e); //选中的商品ID数组
      CartChecked({
        idsNotList: noproductId,
        productIds: e,
      }).then((res) => {
        console.log(res);
        this.retailPrice = res.data.cartTotal.checkedGoodsAmount;
        this.BVnum = res.data.cartTotal.bvAmout;
        this.allCheckedStatus = res.data.cartList.every((item) => item.checked);
        console.log(this.allCheckedStatus);
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../common/style";

.cart_container {
  background: #ededed;
  padding-top: 145px;
  padding-bottom: 30px;

  .cart_inside {
    margin: auto;
    width: 75%;
    //height: 600px;
    background-color: white;

    .title {
      height: 60px;
      width: 97.5%;
      background: #f3f3f3;
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #333;
      padding-left: 30px;
      border-radius: 8px 8px 0 0;
      box-shadow: 0 1px 7px #d4d4d4;
    }

    .shop_list_null {
      height: 550px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .el-button {
        border-color: $neirongColor;
        background-color: white;
        color: black;
      }

      .el-button:hover {
        border-color: $neirongColor;
        background-color: $neirongColor;
        color: white;
      }

      .img {
        width: 150px;
        height: 150px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      p {
        padding: 20px;
        color: rgb(141, 141, 141);
      }
    }

    .cart-table-title {
      align-items: center;
      font-size: 12px;
      color: #838383;
      padding-left: 30px;
      width: 97.5%;
      height: 38px;
      background: #eee;
      border: 1px solid #dbdbdb;
      display: flex;
      justify-content: space-between;

      .xiangqing {
        width: 548px;
        //background-color: orchid;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }

    .cart-table-middle {
      justify-content: space-between;
      align-items: center;
      padding-left: 30px;
      width: 97.5%;
      height: 140px;
      display: flex;
      background: white;

      .left {
        display: flex;
        align-items: center;

        .el-checkbox__input.is-checked + .el-checkbox__label {
          color: $neirongColor;
        }

        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background-color: $neirongColor;
          color: $neirongColor;
          border-color: $neirongColor;
        }

        .el-checkbox__label {
          width: 150px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .img {
          width: 80px;
          height: 80px;
          border: 1px solid #dbdbdb;
          margin-left: 30px;
          margin-right: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .shop-name {
          color: #333;
          font-size: 16px;
        }
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        width: 530px;

        //background-color: red;
        .el-button {
          border-color: $priceColor;
          background-color: white;
          color: black;
        }

        .el-button:hover {
          border-color: $priceColor;
          background-color: $priceColor;
          color: white;
        }

        .price {
          color: #666;
          font-size: 14px;
        }

        .el-input-number {
          width: 130px;
        }

        .Subtotal {
          color: #666;
          font-size: 14px;
          font-weight: 700;
          margin-right: 40px;
        }
      }
    }

    .cart-table-bottom {
      align-items: center;
      padding-left: 30px;
      width: 97.5%;
      height: 90px;
      border: 1px solid #dbdbdb;
      display: flex;
      background: linear-gradient(#fdfdfd, #f9f9f9);
      justify-content: space-between;

      .cart-table-bottom-left {
        .el-checkbox__input.is-checked + .el-checkbox__label {
          color: $neirongColor;
        }

        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background-color: $neirongColor;
          color: $neirongColor;
          border-color: $neirongColor;
        }
      }

      .cart-table-bottom-right {
        height: 100%;
        display: flex;
        align-items: center;
        width: 520px;
        //background-color: pink;
        justify-content: space-around;

        .Selected-products {
          width: 160px;
          height: 50px;
          //background-color: yellow;

          .choose {
            margin: 0px;
            color: $titleColor;
            font-size: 14px;
            text-align: right;

            .choose-num {
              margin-left: 5px;
              margin-right: 5px;
              font-size: 18px;
              color: #d44d44;
              font-weight: 700;
            }
          }

          .all {
            margin: 0px;
            color: #959595;
            font-size: 14px;
            text-align: right;

            .all-num {
              margin-left: 5px;
              margin-right: 5px;
              font-size: 16px;
              color: #959595;
              font-weight: 700;
            }
          }
        }

        .el-button {
          border-color: $neirongColor;
          background-color: white;
          color: black;
        }

        .el-button:hover {
          border-color: $neirongColor;
          background-color: $neirongColor;
          color: white;
        }

        .Amount-payable {
          width: 160px;
          height: 50px;
          display: flex;
          align-items: center;

          //background-color: yellow;
          .choose {
            margin: 0px;
            color: $titleColor;
            font-size: 14px;
          }

          .choose-num {
            margin-left: 5px;
            margin-right: 5px;
            font-size: 18px;
            color: $priceColor;
            font-weight: 700;
          }

          .all {
            margin: 0px;
            color: #959595;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
