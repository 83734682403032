<template>
  <div class="zhuche-container">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="200px"
      class="demo-ruleForm"
    >
      <!-- <div>dee</div> -->
      <div class="zhuche-header">
        {{ i18n.newaccount }}
      </div>
      <el-form-item :label="i18n.Introducernumber" prop="recNo">
        <el-input
          placeholder="如果您沒有任何人介紹號碼，請填寫0000001"
          v-model="ruleForm.recNo"
        ></el-input>
      </el-form-item>
      <el-form-item :label="i18n.Sponsorsname" prop="recName">
        <el-input v-model="ruleForm.recName"></el-input>
      </el-form-item>
      <el-form-item :label="i18n.ChineseEnglishsurname" prop="firstName">
        <el-input v-model="ruleForm.firstName"></el-input>
      </el-form-item>
      <el-form-item :label="i18n.ChineseEnglishname" prop="lastName">
        <el-input v-model="ruleForm.lastName"></el-input>
      </el-form-item>
      <el-form-item :label="i18n.Registrationarea" prop="country">
        <el-select
          @change="select"
          v-model="ruleForm.country"
          :placeholder="i18n.Pleaseselectactivearea"
        >
          <el-option
            v-for="item in option"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-show="isShow" prop="country">
        <el-select
          @change="select2"
          v-model="ruleForm.province"
          :placeholder="i18n.Pleaseselectactivearea"
        >
          <el-option
            v-for="item in option2"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-show="isShow" prop="country">
        <el-select
          @change="select3"
          v-model="ruleForm.city"
          :placeholder="i18n.Pleaseselectactivearea"
        >
          <el-option
            v-for="item in option3"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-show="isShow" prop="country">
        <el-select
          @change="select4"
          v-model="ruleForm.region"
          :placeholder="i18n.Pleaseselectactivearea"
        >
          <el-option
            v-for="item in option4"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="i18n.Gender" prop="gender">
        <el-radio-group v-model="ruleForm.gender">
          <el-radio label="1">{{ i18n.male }}</el-radio>
          <el-radio label="2">{{ i18n.female }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="i18n.Birthday">
        <el-col :span="11">
          <el-form-item prop="birthday">
            <el-date-picker
              value-format="yyyy-MM-dd"
              type="date"
              :placeholder="i18n.Selectadate"
              v-model="ruleForm.birthday"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item :label="i18n.arearnumber" prop="globalRoaming">
        <el-input v-model="ruleForm.globalRoaming"></el-input>
      </el-form-item>
      <el-form-item :label="i18n.Mobilenumber" prop="mobile">
        <el-input v-model="ruleForm.mobile"></el-input>
      </el-form-item>

      <el-form-item :label="i18n.Email" prop="email">
        <el-input v-model="ruleForm.email"></el-input>
      </el-form-item>

      <el-form-item :label="i18n.Verificationcode" prop="code">
        <el-input v-model="ruleForm.code"></el-input>
        <el-button @click="sendCode" style="margin-top: 5px">{{
          i18n.Sendverificationcode
        }}</el-button>
        <el-button @click="sendmobileCode" style="margin-top: 5px">{{
          i18n.Sendmobilecode
        }}</el-button>
      </el-form-item>
      <el-form-item>
        <el-button
          class="zhuce"
          type="primary"
          @click="submitForm('ruleForm')"
          >{{ i18n.register }}</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  regionList,
  authRegister,
  AuthRegisterCaptcha,
  AuthRegisterCaptchaA,
} from "../../api/index.js";
export default {
  computed: {
    i18n() {
      return this.$t("index");
    },
  },
  created() {
    regionList({
      pid: -100,
    }).then((res) => {
      this.option = res.data;
    });
    var s = window.location.href;
    var reg = new RegExp("distNo=\\w+");
    var distNo = reg.exec(s);

    if (distNo !== null) {
      // 转译并填入姓名
      var username = s.split("=")
      let uri = username[2]
      this.ruleForm.recName = decodeURI(uri) 
      var fdN = distNo[0].split("=")[1]
      this.ruleForm.recNo = fdN
    //   console.log(window.location.href,'000000')
    //   console.log(distNo,'11111111111')
    //   console.log(fdN,'22222222')
      console.log(username[2],'name')
    } 
  },
  methods: {
    // 發送手機驗證碼
    sendmobileCode() {
      this.ruleForm.verifyMethod = 1
      if (this.ruleForm.country == "") {
        this.$notify.error({
          title: this.i18n.registrationarea,
        });
      } else {
        AuthRegisterCaptcha({
          mobile: this.ruleForm.mobile,
          // countryId: this.ruleForm.countryId,
          globalRoaming: this.ruleForm.globalRoaming,
        }).then((res) => {
          if (res.errno == 0) {
            this.$notify({
              title: this.i18n.Verificationcodesentsuccessfully,
              type: "success",
            });
          } else {
            this.$notify.error({
              title: res.errmsg,
            });
          }
        });
      }
    },
    sendCode() {
      //发送验证码
      this.ruleForm.verifyMethod = 2
      if (this.ruleForm.country == "") {
        this.$notify.error({
          title: this.i18n.registrationarea,
        });
      } else {
        AuthRegisterCaptchaA({
          email: this.ruleForm.email,
          // countryId: this.ruleForm.countryId,
          // globalRoaming: this.ruleForm.globalRoaming,
        }).then((res) => {
          if (res.errno == 0) {
            this.$notify({
              title: this.i18n.Verificationcodesentsuccessfully,
              type: "success",
            });
          } else {
            this.$notify.error({
              title: res.errmsg,
            });
          }
        });
      }
    },
    submitForm(formName) {
      //提交表单
      // this.ruleForm.globalRoaming= this.ruleForm.arear,
      console.log(this.ruleForm);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //全部验证都通过了
          authRegister(this.ruleForm).then((res) => {
            if (res.errno == 0) {
              this.$notify({
                title: this.i18n.loginwassuccessful,
                message: this.i18n.yourmobilephone,
                type: "success",
              });
              this.$router.push({
                path: "/Login",
              });
            } else {
              this.$notify.error({
                title: res.errmsg,
              });
            }
          });
        } else {
          this.$notify.error({
            title: this.i18n.isanyblank,
          });
          return false;
        }
      });
    },
    select(e) {
      //给省的数据
      console.log("12222222222222222222");
      if (e == -3) {
        // this.eMailshow = false;
        // this.isRequired = false;
        this.isShow = true;
        regionList({
          pid: e,
        }).then((res) => {
          this.option2 = res.data;
        });
        let selectedName = {};
        selectedName = this.option.find((item) => {
          return item.id === e;
        });
        this.ruleForm.countryId = selectedName.id;
        this.ruleForm.country = selectedName.name;
        console.log(this.ruleForm.country);
        console.log(this.ruleForm.countryId);
        this.ruleForm.province = "";
        this.ruleForm.city = "";
        this.ruleForm.region = "";
      } else {
        this.eMailshow = true;
        this.isRequired = true;
        this.isShow = false;
        let selectedName = {};
        selectedName = this.option.find((item) => {
          return item.id === e;
        });
        this.ruleForm.countryId = selectedName.id;
        this.ruleForm.country = selectedName.name;
        console.log(this.ruleForm.country);
        console.log(this.ruleForm.countryId);
        this.ruleForm.province = "";
        this.ruleForm.city = "";
        this.ruleForm.region = "";
      }
    },
    select2(e) {
      //给市区的数据
      regionList({
        pid: e,
      }).then((res) => {
        this.option3 = res.data;
      });
      let selectedName = {};
      selectedName = this.option2.find((item) => {
        return item.id === e;
      });
      this.ruleForm.provinceId = selectedName.id;
      this.ruleForm.province = selectedName.name;
      console.log(this.ruleForm.province);
      console.log(this.ruleForm.provinceId);
      this.ruleForm.city = "";
      this.ruleForm.region = "";
    },
    select3(e) {
      //给区的数据
      regionList({
        pid: e,
      }).then((res) => {
        this.option4 = res.data;
      });
      let selectedName = {};
      selectedName = this.option3.find((item) => {
        return item.id === e;
      });
      this.ruleForm.cityId = selectedName.id;
      this.ruleForm.city = selectedName.name;
      console.log(this.ruleForm.city);
      console.log(this.ruleForm.cityId);
      this.ruleForm.region = "";
    },
    select4(e) {
      let selectedName = {};
      selectedName = this.option4.find((item) => {
        return item.id === e;
      });
      this.ruleForm.regionId = selectedName.id;
      this.ruleForm.region = selectedName.name;
      console.log(this.ruleForm.region);
      console.log(this.ruleForm.regionId);
    },
  },
  data() {
    return {
      isRequired: false,
      eMailshow: true,
      isShow: false,
      option: [],
      option2: [],
      option3: [],
      option4: [],
      ruleForm: {
        recNo: "0000001",
        recName: "香港華富順有限公司",
        firstName: "",
        lastName: "",
        country: "",
        countryId: null,
        province: "",
        provinceId: null,
        city: "",
        cityId: null,
        region: "",
        regionId: null,
        gender: "",
        birthday: "",
        mobile: "",
        email: "",
        code: "",
        globalRoaming: "",
        verifyMethod: null,
      },
      rules: {
        recNo: [
          {
            required: true,
            message: "請輸入介紹人號碼",
            trigger: "blur",
          },
        ],
        recName: [
          {
            required: true,
            message: "請輸入介紹人姓名",
            trigger: "blur",
          },
        ],
        firstName: [
          {
            required: true,
            message: "請輸入中文或英文姓",
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: "請輸入中文或英文名",
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: "請輸入",
            trigger: "change",
          },
        ],
        gender: [
          {
            required: true,
            message: "請輸入性別",
            trigger: "change",
          },
        ],
        globalRoaming: [
          {
            required: true,
            message: "請輸入國際區號",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: "請輸入手機號碼",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "請輸入驗證碼",
            trigger: "blur",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss">
@import "../../common/style";
.zhuche-container {
  width: 97vw;
  //height: 100vh;
  //background-color: red;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 110px;
  form {
    box-shadow: 5px 5px 5px #dadada;
    border: 1px solid #dadada;
    padding: 0px 30px 30px 30px;
    .zhuche-header {
      font-size: 25px;
      width: 100%;
      height: 100px;
      //background-color: yellow;
      display: flex;
      align-items: center;
      justify-content: center;
      //margin-bottom: 10px;
      font-weight: 700;
      color: #606266;
    }
    .zhuce {
      border: 1px solid $neirongColor;
      color: black;
      background-color: white;
    }
    .zhuce:hover {
      border: 1px solid $neirongColor;
      color: white;
      background-color: $neirongColor;
    }
  }
}
</style>
