<template>
	<div class="shopList">
		<div class="shopList-top">
			<div class="shopList-top-left">
				<div @click="changeInside(index,item)" :class="{inside:isInside == index}"
					class="shopList-top-left-inside" v-for="(item,index) in shopInfo.info.gallery" :key="index">
					<img :src="item" />
				</div>
			</div>
			<div class="shopList-top-middle">
				<img :src="bigImg" />
			</div>
			<div class="shopList-top-right">
				<div class="title">
					<h4>{{shopInfo.info.name}}</h4>
					<span class="price">HK${{shopInfo.info.retailPrice}}.00</span>
					<span class="titles">{{shopInfo.info.brief}}</span>
					<!-- <h6>
						<span class="price">￥{{shopInfo.info.retailPrice}}.00</span>
						<span class="titles">{{shopInfo.info.brief}}</span>
					</h6> -->
				</div>
				<div class="num">
					<span class="thisNum">{{i18n.number}}</span>
					<el-input-number v-model="num" @change="handleChange" :min="1" :max="1000000000" label="描述文字">
					</el-input-number>
				</div>
				<div class="btns">
					<el-button type="primary" style="font-size: 12px;" @click="addCart">{{i18n.addtocart}}</el-button>
					<el-button style="font-size: 12px;" @click="buy">{{i18n.Buynow}}</el-button>
				</div>
			</div>
		</div>
		<div class="shopList-bottom">
			<div class="shopList-bottom-title">
				<span>{{i18n.productdetails}}</span>
			</div>
			<div class="item_desc_wrap" v-if="shopInfo.info.detail" v-html="shopInfo.info.detail"></div>
			<div class="item_desc_wrap" v-else style="text-align: center;">
				<p>{{i18n.Nodetails}}</p>
			</div>
		</div>
		<getGood @buy="buys" :title="title" :GoodList="GoodList"></getGood>
		<Foot></Foot>
	</div>
</template>

<script>
	import Foot from '../../components/foot.vue'
	import getGood from '../../components/getGoods.vue'
	import {
		setLocalStorage
	} from '@/utils/local-storage';
	import {
		GoodsDetail,
		CartFastAdd,
		cartAdd,
		GetGoods
	} from '../../api/index.js'
	export default {
		components:{
			getGood,
			Foot
		},
		computed: {
			i18n() {
				return this.$t('index')
			}
		},
		created() {
			console.log(this.$route.query.id)
			GoodsDetail({
				id: this.$route.query.id
			}).then(res => {
				console.log(res)
				this.shopInfo = res.data
				console.log(this.shopInfo)
				this.bigImg = this.shopInfo.info.gallery[0]
				this.shopInfo.info.detail = this.formatRichText(this.shopInfo.info.detail);
			})
			GetGoods().then(res => {
				this.GoodList = res.data
				console.log(this.GoodList)
			})
		},
		data() {
			return {
				GoodList:[],
				title:'相關產品',
				shopInfo: {
					info: {
						gallery: [],
						name: ''
					}
				},
				num: 1,
				isInside: 0,
				bigImg: ''
			}
		},
		methods: {
			buys(id){
				let routeUrl = this.$router.resolve({
					path: '/List',
					query: {
						id
					}
				})
				window.open(routeUrl.href, '_blank')
			},
			addCart(){
				let params = {
					goodsId: Number(this.$route.query.id),
					number: this.num,
					productId: this.shopInfo.productList[0].id
				}
				cartAdd(params).then(res => {
					console.log(res)
					if(res.errno == 0){
						this.$notify({
							title: this.i18n.Addedtocart,
							type: 'success'
						})
						this.$store.commit('add',this.num)
					}else if(res.errmsg == '請登錄'){
						this.$notify.error({
							title: res.errmsg
						})
						setTimeout(() => {
							this.$router.push({
								path:'/Login'
							})
						},2500)
					}else{
						this.$notify.error({
							title: res.errmsg
						})
					}
				})
			},
			buy() {
				let params = {
					goodsId: Number(this.$route.query.id),
					number: this.num,
					productId: this.shopInfo.productList[0].id
				}
				console.log(params)
				CartFastAdd(params).then(res => {
					console.log(res)
					if(res.errno == 0){
						setLocalStorage({
							CartId: res.data
						})
						this.$router.push({
							path:'/placeOrder',
							query:{
								CartId:res.data
							}
						})
					}else if(res.errmsg == '請登錄'){
						this.$notify.error({
							title: res.errmsg
						})
						setTimeout(() => {
							this.$router.push({
								path:'/Login'
							})
						},2500)
					}else{
						this.$notify.error({
							title: res.errmsg
						})
					}
				})
			},
			formatRichText(html) {
				let newContent = html.replace(
					/<img/gi,
					'<img style="width:100%;height:auto;display:block;"'
				);
				return newContent;
			},
			changeInside(index, item) {
				console.log(item)
				this.isInside = index
				this.bigImg = item
			},
			handleChange(value) {
				console.log(value)
			}
		}
	}
</script>

<style lang="scss">
	@import '../../common/style';
	.shopList {
		width: 98vw;
		//height: 8200px;
		background: white;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 145px;

		.shopList-top {
			width: 1000px;
			height: 500px;
			background-color: white;
			border-radius: 8px;
			border: 1px solid #dcdcdc;
			display: flex;
			padding: 60px;

			.shopList-top-left {
				width: 80px;
				height: 100%;
				//background-color: red;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-around;

				.shopList-top-left-inside {
					width: 100%;
					height: 80px;
					border: 1px solid #f0f0f0;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 54px;
						height: 54px;
					}
				}

				.inside {
					border: 3px solid $neirongColor;
				}
			}

			.shopList-top-middle {
				height: 100%;
				width: 460px;
				//background-color: yellow;
				margin-left: 30px;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 440px;
					height: 440px;
				}
			}

			.shopList-top-right {
				height: 100%;
				width: 500px;
				//background-color: green;
				margin-left: 20px;
				padding-top: 25px;

				.title {
					width: 100%;
					padding-bottom: 10px;
					//background-color: yellow;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					height: 180px;

					h4 {
						font-size: 20px;
						margin: 0;
						font-weight: 700;
						color: #f4a460;
					}
					.titles {
						font-size: 13px;
						color: $titleColor;
						font-weight: 400;
					}
					
					.price {
						//margin-right: 65px;
						font-size: 17px;
						color: $titleColor;
					}
				}

				.num {
					width: 100%;
					height: 80px;
					//background-color: red;
					display: flex;
					align-items: center;
					border-top: 1px solid #ebebeb;
					border-bottom: 1px solid #ebebeb;

					.thisNum {
						font-size: 14px;
						color: #8d8d8d;
						margin-right: 50px;
					}
				}

				.btns {
					width: 100%;
					height: 130px;
					//background-color: pink;
					display: flex;
					align-items: center;
					.el-button{
						background-color: white;
						border-color: $neirongColor;
						color: black;
					}
					.el-button:hover{
						background-color: $neirongColor;
						color: white;
					}
				}
			}
		}

		.shopList-bottom {
			margin-top: 30px;
			width: 1100px;
			margin-bottom: 30px;
			background-color: white;
			border-radius: 8px;

			.shopList-bottom-title {
				height: 60px;
				display: flex;
				align-items: center;
				z-index: 10;
				font-size: 30px;
				span{
					padding-bottom: 10px;
					border-bottom: 3px solid $neirongColor;
				}
			}

			.item_desc_wrap {
				padding: 10px;
				width: 80%;
				margin: auto;
			}
		}
	}
</style>
