<template>
	<div>
		<Title :TitleName="TitleName"></Title>
		<div class="order-container">
			<div class="order-container-header" v-for="(item,index) in orderGoods" :key="index">
				<div class="order-container-header-left">
					<img :src="item.picUrl" />
				</div>
				<div class="order-container-header-right">
					<div class="title">{{item.goodsName}}</div>
					<div class="bottom">
						<div>{{i18n.Unitprice}}：HK${{item.price+'.00'}}</div>
						<div>{{i18n.number}}：x{{item.number}}</div>
					</div>
				</div>
			</div>
			<div class="order-container-bottom">
				<div class="inside">{{i18n.Total}}：HK${{orderInfo.goodsPrice}}</div>
				<div class="inside">{{i18n.usecode}}：HK${{orderInfo.couponPrice}}</div>
				<div class="inside">{{i18n.Voucher}}：HK${{orderInfo.voucherAmt}}</div>
				<div class="inside">{{i18n.Freight}}：HK${{orderInfo.freightPrice}}</div>
				<div class="inside">{{i18n.Taxation}}：HK${{orderInfo.tax}}</div>
				<div class="inside">{{i18n.Total}}：HK${{orderInfo.actualPrice}}</div>
				<div style="display: flex;justify-content: center;margin-top: 30px;">
					<el-button v-if="orderInfo.handleOption.delete" class="danger" type="danger" @click="deleteOrders">{{i18n.deleteorder}}</el-button>
					<el-button v-if="orderInfo.handleOption.cancel" class="danger" type="danger" @click="cancelOrder">{{i18n.cancelorder}}</el-button>
					<el-button class="pay" v-if="orderInfo.handleOption.pay && orderInfo.actualPrice == 0" type="primary" @click="SubmitZeroPay">{{i18n.pay}}</el-button>
					<el-button class="pay" v-if="orderInfo.handleOption.pay" type="primary" @click="pays">{{i18n.pay}}</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		OrderDetail,
		deleteOrder,
		cancelOrder,
		SubmitZeroPay,
		PayMoney
	} from '../../../api/index.js'
	import Title from '../../../components/title.vue'
	export default {
		computed: {
			i18n() {
				return this.$t('index')
			}
		},
		created() {
			this.init()
		},
		components: {
			Title
		},
		data() {
			return {
				TitleName: '',
				orderGoods: [],
				orderInfo: {}
			}
		},
		methods: {
			pays(){
				PayMoney({orderId:this.orderInfo.id}).then(res => {
					console.log(res)
					window.location.href = res.data.href
				})
			},
			init(){
				OrderDetail({
					orderId: Number(this.$route.query.id)
				}).then(res => {
					console.log(res)
					this.orderGoods = res.data.orderGoods
					this.orderInfo = res.data.orderInfo
					console.log(this.orderInfo)
				})
				this.TitleName = '訂單號：' + this.$route.query.orderSn
			},
			deleteOrders() {
				this.$confirm(this.i18n.Deleteorder + '?', this.i18n.Tips, {
					confirmButtonText: this.i18n.Confirm,
					cancelButtonText: this.i18n.cancel,
					type: 'warning'
				}).then(() => {
					deleteOrder({
						orderId: this.orderInfo.id
					}).then(res => {
						console.log(res)
						this.$message({
							type: 'success',
							message: this.i18n.Successfullydeleted
						})
						this.$router.push({
							path: '/userInfo/MyOrder'
						})
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: this.i18n.Deletioncanceled
					})
				})
			},
			cancelOrder(){
				this.$confirm(this.i18n.Doyouwanttocancelorder + '?', this.i18n.Tips, {
					confirmButtonText: this.i18n.Confirm,
					cancelButtonText: this.i18n.cancel,
					type: 'warning'
				}).then(() => {
					cancelOrder({
						orderId: this.orderInfo.id
					}).then(res => {
						console.log(res)
						this.$message({
							type: 'success',
							message: this.i18n.Cancelsuccessfully
						})
						this.init()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: this.i18n.Cancelled
					})
				})
			},
			SubmitZeroPay(){
				SubmitZeroPay({
					orderId:this.orderInfo.id
				}).then(res => {
					console.log(res)
				})
			}
		}
	}
</script>

<style lang="scss">
	@import '../../../common/style';
	.order-container {
		width: 94%;
		height: 700px;
		//background-color: red;
		padding: 30px;

		.order-container-header {
			width: 100%;
			height: 200px;
			//background-color: green;
			border-bottom: 1px solid #eeeef0;
			border-top: 1px solid #eeeef0;
			display: flex;
			// justify-content: space-around;
			align-items: center;

			.order-container-header-left {
				width: 20%;
				height: 90%;
				//background-color: pink;
				margin-left: 30px;
				margin-right: 30px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.order-container-header-right {
				width: 30%;
				height: 90%;

				//background-color: purple;
				.title {
					font-size: 20px;
					color: #8D8D8D;
				}

				.bottom {
					width: 100%;
					height: 100px;
					//background-color: yellow;
					margin-top: 20px;
					font-size: 17px;
					color: #8D8D8D;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
				}
			}
		}

		.order-container-bottom {
			width: 96%;
			height: 400px;
			//background-color: green;
			padding: 20px;
			margin-left: 30px;
			.danger{
				border-color: #F56C6C;
				background-color: white;
				color: black;
			}
			.danger:hover{
				border-color: #F56C6C;
				background-color: #F56C6C;
				color: white;
			}
			.pay{
				border-color: $neirongColor;
				background-color: white;
				color: black;
			}
			.pay:hover{
				border-color: $neirongColor;
				background-color: $neirongColor;
				color: white;
			}
			.inside {
				font-size: 17px;
				color: #8D8D8D;
				margin-left: 12px;
				margin-bottom: 20px;
			}
		}
	}
</style>
