<template>
  <div class="forget-container">
    <!-- <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    > -->
      <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
        
        <el-tab-pane :label="i18n.UseEmail" name="first">
              
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            :label-width="lang.language == 'en-US'?'136px':'100px'"
            class="demo-ruleForm"
          >
            <div class="forget-header">
              {{ i18n.Findthepassword }}
            </div>
            <el-form-item :label="i18n.Email" prop="email">
              <el-input v-model="ruleForm.email"></el-input>
            </el-form-item>
            <el-form-item :label="i18n.newPassword" prop="newpassword">
              <el-input v-model="ruleForm.newpassword"></el-input>
            </el-form-item>
            <el-form-item :label="i18n.Verificationcode" prop="code">
              <el-input v-model="ruleForm.code"></el-input>
            </el-form-item>
              <div class="allbtn">
                <el-button @click="sendCode" style="margin-top: 5px">{{
                  i18n.Sendverificationcode
                }}</el-button>
              </div>

            <el-form-item>
              <el-button
                class="sure"
                style="margin-top: 30px"
                type="primary"
                @click="submitForm('ruleForm')"
                >{{ i18n.Confirm }}</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
        
        <el-tab-pane :label="i18n.UseSMS" name="second">
          <el-form
            :model="ruleFormPhone"
            :rules="rulesPhone"
            ref="ruleFormPhone"
            :label-width="lang.language == 'en-US'?'136px':'100px'"
            class="demo-ruleForm"
          >
            <div class="forget-header">
              {{ i18n.Findthepassword }}
            </div>
            
            <el-form-item :label="i18n.arearnumber" prop="globalRoaming">
              <el-input v-model="ruleFormPhone.globalRoaming"></el-input>
            </el-form-item>
            <el-form-item :label="i18n.Telephone" prop="number">
              <el-input v-model="ruleFormPhone.number"></el-input>
            </el-form-item>
            <el-form-item :label="i18n.newPassword" prop="newpassword">
              <el-input v-model="ruleFormPhone.newpassword"></el-input>
            </el-form-item>

            <el-form-item :label="i18n.Verificationcode" prop="code">
              <el-input v-model="ruleFormPhone.code"></el-input>
            </el-form-item>
              <div class="allbtn">
                <el-button @click="sendmodileCode" style="margin-top: 5px">{{
                  i18n.Sendmobilecode
                }}</el-button>
              </div>

            <el-form-item>
              <el-button
                class="sure"
                style="margin-top: 30px"
                type="primary"
                @click="submitForm('ruleFormPhone')"
                >{{ i18n.Confirm }}</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    <!-- </el-form> -->
    
  </div>
</template>

<script>
import { getLocalStorage } from "@/utils/local-storage";
import { ResetCaptcha, AuthReset } from "../../api/index.js";
export default {
  computed: {
    i18n() {
      return this.$t("index");
    },
  },
  data() {
    return {
      activeName: 'first',
      ruleForm: {
        email: "",
        code: "",
        newpassword: "",
        // number: "",
        // globalRoaming: "",
      },
      ruleFormPhone: {
        // email: "",
        code: "",
        number: "",
        globalRoaming: "",
        newpassword: "",
      },
      rules: {
        email: [
          {
            required: true,
            message: "請輸入郵箱",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "請輸入驗證碼",
            trigger: "blur",
          },
        ],
        newpassword: [
          {
            required: true,
            message: "請輸入新密碼",
            trigger: "blur",
          },
        ],
      },
      rulesPhone: {
        number: [
          {
            required: true,
            message: "請輸入手機號碼",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "請輸入驗證碼",
            trigger: "blur",
          },
        ],
        globalRoaming: [
          {
            required: true,
            message: "請輸入國際區號",
            trigger: "blur",
          },
        ],
        newpassword: [
          {
            required: true,
            message: "請輸入新密碼",
            trigger: "blur",
          },
        ],
      },
      lang: '',
    };
  },
		created() {
			this.lang = getLocalStorage('language')
		},
  methods: {
    sendmodileCode() {
      console.log('手機驗證');
      ResetCaptcha({
        globalRoaming: this.ruleFormPhone.globalRoaming,
        username: this.ruleFormPhone.number,
      }).then((res) => {
        if (res.errno == 0) {
          this.$notify({
            title: this.i18n.Verificationcodesentsuccessfully,
            type: "success",
          });
        } else {
          this.$notify.error({
            title: res.errmsg,
          });
        }
      });
    },
    sendCode() {
      console.log('郵箱驗證');
      ResetCaptcha({
        globalRoaming: '00',
        username: this.ruleForm.email,
      }).then((res) => {
        if (res.errno == 0) {
          this.$notify({
            title: this.i18n.Verificationcodesentsuccessfully,
            type: "success",
          });
        } else {
          this.$notify.error({
            title: res.errmsg,
          });
        }
      });
    },

    submitForm(formName) {
      console.log(formName);
      console.log(11111111111111122);
      if(formName == 'ruleForm') {
        console.log('f')
        this.ruleFormPhone.code = ''
        this.ruleFormPhone.number = ''
        this.ruleFormPhone.globalRoaming = ''
        this.ruleFormPhone.newpassword = ''
      }else if (formName == 'ruleFormPhone') {
        console.log('s')
        this.ruleForm.email = ''
        this.ruleForm.code = ''
        this.ruleForm.newpassword = ''
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          AuthReset({
            username: this.ruleForm.email || this.ruleFormPhone.number,
            code: this.ruleForm.code || this.ruleFormPhone.code,
            newPassword: this.ruleForm.newpassword || this.ruleFormPhone.newpassword,
          }).then((res) => {
            console.log(2222222222222);
            if (res.errno == 0) {
              console.log("22--------");
              this.$notify({
                title: this.i18n.SMS,
                type: "success",
              });
              console.log("--------------");
              setTimeout(() => {
                this.$router.push({
                  path: "/Login",
                });
              }, 2000);
            } else {
              console.log(2333333);
              this.$notify.error({
                title: res.errmsg,
              });
            }
          });
        } else {
          console.log(1444444444444441);
          this.$notify.error({
            title: this.i18n.blank,
          });
          return false;
        }
      });
    
    },

    handleClick(tab, event) {
      console.log(tab, event);
    }
  },
};
</script>

<style lang="scss">
@import "../../common/style";
.allbtn {
  display: flex;
  justify-content: space-around;
}
.forget-container {
  width: 97vw;
  // height: 92vh;
  height: 115vh;
  //background-color: red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    box-shadow: 5px 5px 5px #dadada;
    // border: 1px solid #dadada;
    padding: 0px 30px 30px 30px;
    .forget-header {
      width: 100%;
      height: 100px;
      //background-color: yellow;
      display: flex;
      align-items: center;
      justify-content: center;
      //margin-bottom: 10px;
      font-weight: 700;
      font-size: 25px;
      color: #606266;
    }
    .sure {
      border-color: $neirongColor;
      background-color: white;
      color: black;
    }
    .sure:hover {
      border-color: $neirongColor;
      background-color: $neirongColor;
      color: white;
    }
  }
  .el-tabs__item.is-active {
    color: #FF974B;
  }
  .el-tabs__active-bar {
    background-color: #FF974B;
  }
  .el-tabs__item:hover {
    color: #FF974B;
  }
  .el-tabs__header {
    margin: 0;
  }
  .el-tabs {
    border: 1px solid #dadada;
  }
}
</style>