<template>
  <div
    :style="{ 'margin-top': $route.path == '/' ? '90px' : '20px' }"
    class="foot-container"
  >
    <div class="foot-contact">
      <!-- <div class="foot-contact-top">
				<img src="../assets/logo22.png" />
			</div> -->
      <div class="foot-contact-bottom">
        <div
          :class="[lang.language == 'en-US' ? 'inside2' : 'inside']"
          style="margin-left: 50px"
        >
          <div class="title">{{ i18n.Contact }}</div>
          <div>Hong Kong Huafushun Limited</div>
          <br />
          <div class="smallTitle">
            <div class="addressLeft">Address:</div>
            <div class="conTentext">
              Unit B13, 6th Floor, Merit Industrial Centre, No. 94 To Kwa Wan
              Road, Kowloon.
            </div>
          </div>
          <div class="smallTitle">
            <div class="addressLeft">Phone number:</div>
            <div class="conTentext">+852 26130316</div>
          </div>
          <div class="smallTitle">
            <div class="addressLeft">WhatsApp:</div>
            <div class="conTentext">+852 96969558</div>
          </div>
          <div class="smallTitle">
            <div class="addressLeft">WeChat:</div>
            <div class="conTentext">96969558</div>
          </div>
          <div class="smallTitle">
            <div class="addressLeft">Email:</div>
            <div class="conTentext">puremehkg@gmail.com</div>
          </div>
          <!-- <div class="smallTitle">{{i18n.Termsofuse}}</div>
					<div class="smallTitle">{{i18n.Privacypolicy}}</div> -->
        </div>
        <div
          :class="[lang.language == 'en-US' ? 'inside2' : 'inside']"
          style="margin-right: -220px"
        >
          <div class="title">{{ i18n.Companyinformation }}</div>
          <div>
            <a
              href="https://www.google.com/maps/@22.327532,114.205102,14z?hl=en"
            >
              <img class="img" src="../assets/12.png" />
            </a>
          </div>
          <!-- <div class="title">{{i18n.Informationinquiry}}</div>
					<div class="smallTitle">{{i18n.Frequentlyaskedquestions}}</div>
					<div class="smallTitle">{{i18n.Aboutdelivery}}</div>
					<div class="smallTitle">{{i18n.Aboutreturns}}</div> -->
          <!-- <div class="smallTitle">{{i18n.Ordertracking}}</div> -->
        </div>
      </div>
    </div>
    <!-- <div class="bottom_by">© 2018 by Nanpeng Technology. All right reserved.</div> -->
  </div>
</template>

<script>
import { getLocalStorage } from "@/utils/local-storage";
export default {
  created() {
    this.lang = getLocalStorage("language");
  },
  computed: {
    i18n() {
      return this.$t("index");
    },
  },
  data() {
    return {
      lang: "",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../common/style";
.foot-container {
  //background-color: #20554f;

  width: 100%;
  height: 250px;
  margin-top: 60px !important;
  .foot-contact {
    width: 90%;
    height: 100%;

    //background-color: red;
    margin: auto;
    .foot-contact-top {
      height: 30%;
      width: 100%;
      padding-bottom: 20px;
      //background-color: yellow;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $neirongColor;
      justify-content: center;
      img {
        width: 200px;
        height: 100px;
      }
    }
    .foot-contact-bottom {
      height: 70%;
      width: 100%;

      // justify-content: space-between;
      justify-content: space-around;
      //background-color: green;
      display: flex;
      .inside {
        width: 30%;
        height: 100%;
        margin: 0 50px;
        //background-color: red;
        padding-top: 30px;
        .imgs {
          width: 100%;
          display: flex;
          margin-top: 15px;
          text-align: center;
          img {
            margin-right: 5px;
            height: 230px;
            width: 280px;
          }
        }
        .title {
          font-size: 18px;
          margin-bottom: 10px;
        }
        .smallTitle {
          font-size: 15px;
          margin-bottom: 10px;
          font-size: 14px;
          color: #7a7a7a;
          width: 400px;
          display: flex;
          .addressLeft {
            width: 150px;
          }
          .conTentext {
            flex: 1;
          }
        }
      }
      .inside2 {
        width: 30%;
        height: 100%;
        margin: 0 50px;
        //background-color: red;
        padding-top: 30px;
        .imgs {
          width: 100%;
          display: flex;
          margin-top: 15px;
          img {
            margin-right: 5px;
            height: 230px;
            width: 280px;
          }
        }
        .title {
          font-size: 18px;
          margin-bottom: 10px;
        }
        .smallTitle {
          font-size: 15px;
          margin-bottom: 10px;
          font-size: 14px;
          color: #7a7a7a;
          width: 400px;
          display: flex;
          .addressLeft {
            width: 150px;
          }
          .conTentext {
            flex: 1;
          }
        }
      }
    }
  }
  .bottom_by {
    text-align: center;
    padding-top: 20px;
  }
  .img {
    height: 230px;
    width: 290px;
  }
}
</style>
