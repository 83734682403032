<template>
  <div class="palce_container">
    <div class="palce_inside">
      <div class="top">
        <h1 class="titles">{{ i18n.Confirmclosing }}</h1>
        <div class="success">
          <div
            class="success-info"
            v-for="(item, index) in shangPingInfo.checkedGoodsList"
            :key="index"
          >
            <div style="display: flex">
              <div class="success-info-img">
                <img :src="item.picUrl" />
              </div>
              <div class="success-info-info">
                <div style="white-space: nowrap">{{ item.goodsName }}</div>
                <div style="color: #d44d44">HK${{ item.price }}</div>
                <div style="width: 400px; font-size: 13px">
                  {{ item.brief }}
                </div>
              </div>
            </div>
            <div class="success-info-num">X{{ item.number }}</div>
          </div>
          <div class="success-manyInside">
            <div class="success-manyInside-one">
              <div>{{ i18n.Totalcommodities }}</div>
              <div>HK${{ shangPingInfo.goodsTotalPrice }}</div>
            </div>
            <div class="success-manyInside-one">
              <div>{{ i18n.Freight }}</div>
              <div>HK${{ shangPingInfo.freightPrice }}</div>
            </div>
            <div class="success-manyInside-one">
              <div>{{ i18n.Taxation }}</div>
              <div>HK$0.00</div>
            </div>
            <div class="success-manyInside-one">
              <div>{{ i18n.Preferentialamount }}</div>
              <!-- <div>HK$:{{ shangPingInfo.couponPrice }}.00</div> -->
              <el-select v-model="couponsValue" clearable placeholder=" ">
                <el-option
                  v-for="item in coupons"
                  :key="item.value"
                  :label="`-${item.value}`"
                  :value="item.id"
                  :disabled="shangPingInfo.goodsTotalPrice < item.min"
                >
                </el-option>
              </el-select>
            </div>
            <div class="success-manyInside-one">
              <div>{{ i18n.Ordernotes }}</div>
              <el-input
                style="width: 400px"
                v-model="message"
                :placeholder="i18n.Pleaseinputthecontent"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="Pay_immediately">
          <div></div>
          <div class="Pay_immediately_inside">
            <div
              :class="[
                lang.language == 'en-US'
                  ? 'Pay_immediately_two'
                  : 'Pay_immediately_one',
              ]"
            >
              <span
                style="font-size: 14px; color: #666666; margin-right: 10px"
                :style="{ width: lang.language == 'en-US' ? '100px' : '60px' }"
                >{{ i18n.Orderamount }}:</span
              >
              <span style="font-size: 16px; color: #d44d44; font-weight: 700"
                >HK$</span
              >
              <span style="font-size: 16px; color: #d44d44; font-weight: 700"
                >{{ shangPingInfo.goodsTotalPrice }}.00</span
              >
            </div>
            <div
              :class="[
                lang.language == 'en-US'
                  ? 'Pay_immediately_two'
                  : 'Pay_immediately_one',
              ]"
            >
              <span
                style="font-size: 14px; color: #666666; margin-right: 10px"
                :style="{ width: lang.language == 'en-US' ? '160px' : '88px' }"
                >{{ i18n.Actualamountpayable }}:</span
              >
              <span style="font-size: 16px; color: #d44d44; font-weight: 700"
                >HK$</span
              >
              <span style="font-size: 16px; color: #d44d44; font-weight: 700">{{
                (
                  shangPingInfo.goodsTotalPrice -
                  ((coupons.find((item) => item.id == couponsValue) || {})
                    .value || 0)
                ).toLocaleString()
              }}</span>
            </div>
            <div
              :class="[
                lang.language == 'en-US'
                  ? 'Pay_immediately_two'
                  : 'Pay_immediately_one',
              ]"
            >
              <el-button @click="pay" type="primary">{{
                i18n.Payimmediately
              }}</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div
          v-if="shangPingInfo.checkedAddress.id == 0"
          @click="$router.push({ path: '/userInfo/HarvestAddress' })"
          class="Receiving_information"
          style="
            color: red;
            cursor: pointer;
            text-align: right;
          "
        >
          {{ i18n.possible }}
        </div>
        <div v-else class="Receiving_information">
          <p
            style="
              font-weight: bolder;
              color: #333;
              font-size: 14px;
              margin-top: 30px;
              margin-bottom: 30px;
            "
          >
            {{ i18n.Receivinginformation }}
          </p>
          <p
            style="
              font-weight: 100;
              color: #666;
              font-size: 14px;
              margin-top: 0px;
              margin-bottom: 0px;
            "
          >
            {{ i18n.name }}：{{ shangPingInfo.checkedAddress.name }}
          </p>
          <p
            style="
              font-weight: 100;
              color: #666;
              font-size: 14px;
              margin-top: 0px;
              margin-bottom: 0px;
            "
          >
            {{ i18n.contactnumber }}：{{ shangPingInfo.checkedAddress.tel }}
          </p>
          <p
            style="
              font-weight: 100;
              color: #666;
              font-size: 14px;
              margin-top: 0px;
              margin-bottom: 0px;
            "
          >
            {{ i18n.Detailedaddress }}：{{
              shangPingInfo.checkedAddress.addressDetail
            }}
          </p>
          <el-button
            style="margin-top: 10px; font-size: 14px"
            type="primary"
            @click="$router.push({ path: '/userInfo/HarvestAddress' })"
            >{{ i18n.Changeaddress }}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CartCheckout,
  orderSubmit,
  couponSelectList,
} from "../../api/index.js";
import { getLocalStorage } from "@/utils/local-storage";
export default {
  computed: {
    i18n() {
      return this.$t("index");
    },
  },
  created() {
    this.lang = getLocalStorage("language");
    const { AddressId, CartId } = getLocalStorage("CartId", "AddressId");
    console.log(CartId);
    if (CartId == null) {
      this.$notify.error({
        title: this.i18n.first,
      });
      setTimeout(() => {
        this.$router.push({
          path: "/",
        });
      }, 2000);
    } else {
      let parmes = {
        cartId: Number(CartId),
        addressId: AddressId,
        couponId: 0, //这两个还不道是什么，暂时为0
        voucherId: 0, //这两个还不道是什么，暂时为0
        grouponRulesId: 0,
      };
      CartCheckout(parmes).then((res) => {
        console.log(res);
        this.shangPingInfo = res.data;
        console.log(this.shangPingInfo);
      });

      couponSelectList({ cartId: CartId, grouponRulesId: 0 }).then((res) => {
        var cList = res.data.list;
        this.coupons = [];
        for (var i = 0; i < cList.length; i++) {
          var c = cList[i];

          var coupon = {
            id: c.id,
            name: c.name,
            condition: c.min,
            value: c.discount,
            description: c.desc,
            startAt: new Date(c.startTime).getTime() / 1000,
            endAt: new Date(c.endTime).getTime() / 1000,
            valueDesc: c.discount,
            unitDesc: "元",
            min: c.min,
          };
          this.coupons.push(coupon);

          if (c.id === this.couponId) {
            this.chosenCoupon = i;
            break;
          }
        }
      });
    }
  },
  data() {
    return {
      shangPingInfo: {},
      payMoney: "58.00",
      message: "",
      lang: "",
      coupons: [],
      couponsValue: 0,
    };
  },
  methods: {
    pay() {
      const { AddressId, CartId } = getLocalStorage("AddressId", "CartId");
      if (AddressId == null || AddressId == undefined || AddressId == "") {
        this.$notify.error({
          title: this.i18n.address,
        });
        return;
      }
      let parmes = {
        addressId: AddressId,
        cartId: CartId,
        couponId: this.couponsValue ? this.couponsValue : 0, //这两个还不道是什么，暂时为0
        voucherId: 0, //这两个还不道是什么，暂时为0
        grouponLinkId: 0,
        grouponRulesId: 0,
        message: this.message,
      };
      console.log(parmes);
      orderSubmit(parmes).then((res) => {
        if (res.errno == 0) {
          console.log(res.data.orderId);
          console.log(res);
          this.$router.push({
            path: "/Payment",
            query: {
              orderId: res.data.orderId,
            },
          });
        } else {
          this.$notify.error({
            title: res.errmsg,
          });
          if(this.shangPingInfo.checkedAddress.id == 0) {
          this.$router.push({
            path: "/userInfo/HarvestAddress",
          });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../common/style";
.palce_container {
  background: #f8f8fa;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 50px;

  .palce_inside {
    width: 80%;
    //height: 1200px;
    //background-color: red;

    .top {
      width: 100%;
      //height: 60%;
      background-color: white;
      margin-top: 35px;

      .titles {
        font-weight: 500;
        height: 60px;
        width: 97.5%;
        display: flex;
        align-items: center;
        font-size: 25px;
        color: #333;
        background-color: #f8f8fa;
      }

      .success {
        width: 96%;
        //height: 540px;
        padding: 20px;
        //background-color: yellow;
        display: flex;
        flex-direction: column;
        align-items: center;

        .success-info {
          width: 85%;
          //height: 100px;
          //background-color: red;
          padding-top: 20px;
          padding-bottom: 20px;
          display: flex;
          justify-content: space-between;

          .success-info-img {
            height: 100px;
            width: 100px;
            //background-color: greenyellow;
            // margin-left: 100px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .success-info-info {
            height: 130px;
            //width: 150px;
            //background-color: pink;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 30px;
          }

          .success-info-num {
            width: 150px;
            //background-color: orange;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .success-manyInside {
          width: 100%;
          height: 400px;
          //background-color: green;
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .success-manyInside-one {
            width: 80%;
            height: 19.5%;
            //background-color: peachpuff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 100px;
            padding-right: 100px;
            border-top: 1px solid #999;
            border-bottom: 1px solid #999;
          }
        }
      }

      .Pay_immediately {
        width: 95%;
        height: 76px;
        border-top: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .Pay_immediately_inside {
          display: flex;
          align-items: center;
          width: 500px;
          justify-content: space-around;
          .Pay_immediately_one {
            height: 100%;
            display: flex;
            align-items: center;
            .el-button {
              border-color: $neirongColor;
              background-color: white;
              color: black;
            }
            .el-button:hover {
              border-color: $neirongColor;
              background-color: $neirongColor;
              color: white;
            }
          }
          .Pay_immediately_two {
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 10px;
            .el-button {
              border-color: $neirongColor;
              background-color: white;
              color: black;
            }
            .el-button:hover {
              border-color: $neirongColor;
              background-color: $neirongColor;
              color: white;
            }
          }
        }
      }
    }

    .bottom {
      width: 100%;
      height: 40%;

      //background-color: green;
      // .bar {
      // 	width: 100%;
      // 	height: 70px;
      // 	background-color: #ededed;
      // 	border-bottom: 1px solid #d5d5d5;
      // }

      .Receiving_information {
        width: 95%;
        height: 155px;
        //background-color: pink;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 25px;
        border-bottom: 1px solid #d5d5d5;
      }
    }
  }
}
</style>
