<template>
  <div class="goods-container">
    <div class="goods-container-header"></div>
    <div v-for="item in $store.state.productList" :key="item.id">
      <div class="goods-container-title">
        <div class="xian"></div>
        <span>
          {{ getLocalLanguage == "en-US" ? item.usName : item.name }}</span
        >
        <div class="xian"></div>
      </div>
      <div class="goods-container-contact">
        <div class="noShops" v-if="item.items.length == 0">
          {{ i18n.Noproductsonsale }}
        </div>
        <div
          v-else
          class="hot-shop-inside"
          v-for="childitem in item.items"
          :key="childitem.id"
        >
          <div class="header">
            <el-image lazy :src="childitem.picUrl" />
          </div>
          <h6>{{ childitem.name }}</h6>
          <!-- <div class="info">{{childitem.brief}}</div> -->
          <h3>HK${{ childitem.retailPrice }}</h3>
          <div class="button">
            <el-button type="primary" @click="jump(childitem.id)">{{
              i18n.purchase
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import { getLocalStorage } from "@/utils/local-storage";
import { GetL1 } from "../../api/index.js";
import Foot from "../../components/foot.vue";
export default {
  data() {
    return {
      getLocalLanguage: "",
    };
  },
  computed: {
    i18n() {
      return this.$t("index");
    },
  },
  components: {
    Foot,
  },
  created() {
    this.getLocalLanguage = localStorage.getItem("language");
    let index = getLocalStorage("insideListColorIndex");
    console.log(index.insideListColorIndex);
    GetL1().then((res) => {
      console.log(res);
      if (index.insideListColorIndex) {
        this.$store.dispatch(
          "clickProduct",
          res.data[index.insideListColorIndex].id
        );
      } else {
        this.$store.dispatch("clickProduct", res.data[0].id);
      }
    });
  },
  methods: {
    jump(id) {
      this.$router.push({
        path: "/List",
        query: {
          id,
        },
      });
    },
  },
};
</script>


<style lang="scss">
@import "../../common/style";

.goods-container {
  background: #ededed;
  padding-top: 100px;
  padding-bottom: 30px;

  .goods-container-header {
    // margin: auto;
    height: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 48px;
  }

  .goods-container-title {
    width: 1220px;
    height: 50px;
    //background-color: red;
    margin-top: 30px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .xian {
      width: 480px;
      height: 2px;
      background-color: #333;
    }

    span {
      color: #333;
      font-size: 10px;
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  .goods-container-contact {
    margin-top: 30px;
    margin: auto;
    //height: 2150px;
    width: 1220px;
    display: flex;
    //align-items: center;
    background-color: white;
    flex-wrap: wrap;
    .noShops {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100px;
    }
    .hot-shop-inside {
      border: 1px solid #efefef;
      //padding-top: 50px;
      width: 300px;
      height: 430px;
      background-color: white;

      .info {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #7d7e80;
        font-size: 13px;
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 17px;
      }

      .header {
        width: 100%;
        height: 260px;
        //background-color: hotpink;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 206px;
          height: 206px;
        }
      }

      h6 {
        margin-bottom: 0px;
        margin-top: 0px;
        font-weight: 400;
        font-size: 14px;
        color: #424242;
        text-align: center;
      }

      h3 {
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        color: $priceColor;
        font-size: 17px;
        font-weight: 700;
      }

      .button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .el-button {
          font-size: 12px;
          background-color: white;
          color: black;
          border: 1px solid $neirongColor;
        }

        .el-button:hover {
          background-color: $neirongColor;
          color: white;
        }
      }
    }
  }
}
</style>
