<template>
	<div class="search-container">
		<div v-if="list.length == 0" class="noSearch">沒有搜索到任何產品噢</div>
		<div v-else class="list">
			<div @click="look(item.id)" v-for="(item,index) in list" :key="index" class="list-inside">
				<div class="list-inside-img">
					<img :src="item.picUrl" />
				</div>
				<div class="list-inside-text">
					<div class="list-inside-text-name">{{item.name}}</div>
					<div class="list-inside-text-conatiner">{{item.brief}}</div>
					<div style="color: #EE0A24;font-size: 17px;font-weight: 700;">HK${{item.retailPrice}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { GoodsList } from '../../api/index.js'
	export default {
		created() {
			console.log(this.$route.query.input)
			GoodsList({
				keyword: this.$route.query.input,
				page: 1,
				limit: 10,
				categoryId: 0
			}).then(res => {
				this.list = res.data.list
			})
		},
		methods:{
			look(id){
				this.$router.push({
					path:'/List',
					query:{
						id
					}
				})
			}
		},
		data(){
			return{
				list:[]
			}
		}
	}
</script>

<style lang="scss">
	@import '../../common/style';
	.search-container{
		margin-top: 150px;
		width: 97vw;
		// height: 100vh;
		//background-color: red;
		display: flex;
		justify-content: center;
		.noSearch{
			margin-top: 100px;
			font-size: 30px;
			color: #AAAAAA;
		}
		.list{
			margin-top: 20px;
			width: 20%;
			//height: 600px;
			//background-color: greenyellow;
			.list-inside{
				margin-bottom: 10px;
				width: 100%;
				height: 100px;
				//background-color: pink;
				display: flex;
				align-items: center;
				cursor: pointer;
				.list-inside-img{
					height: 90px;
					width: 90px;
					//background-color: orangered;
					margin-left: 30px;
					img{
						width: 100%;
						height: 100%;
					}
				}
				.list-inside-text{
					height: 90px;
					//width: 200px;
					//background-color: orangered;
					margin-left: 30px;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					.list-inside-text-name{
						width: 150px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						color: $titleColor;
					}
					.list-inside-text-conatiner{
						width: 150px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						color: #7D7E80;
						font-size: 13px;
					}
				}
			}
		}
	}
</style>
