/*
 * @Author: Zzy
 * @Date: 2021-07-19 10:10:28
 * @LastEditTime: 2021-07-27 14:12:17
 * @LastEditors: Zzy
 * @Description: 
 * @FilePath: /hfs-mall-pc/src/api/index.js
 * Creat by Zzy
 */
// 导入封装好的网络请求类工具
import Network from './network';

// 封装各种接口请求
// export const 接口名 = () => Network.get('/路由',参数对象);

//Home.vue
export const getHome = () => Network.get('/wx/home/index')//首页数据接口

//Detail.vue
export const GetL1 = (data) => Network.get('/wx/catalog/get_l1_category',data)//获取列表一级菜单
export const GetL2 = (data) => Network.get('/wx/catalog/get_l2_category',data)//获取列表二级菜单级对应的子项数据

//Login.vue
export const AuthLoginByAccount = (data) => Network.post('/wx/auth/login',data)//用户登录

//userInfo.Vue
export const UserDetail = () => Network.get('/wx/user/detail')//获取用户详情
export const getTwoCode = (data) => Network.get('/wx/user/getTwoCode',data)//获取二维码
export const OrderList = (data) => Network.get('/wx/order/list',data)//获取订单列表
export const OrderDetail = (data) => Network.get('/wx/order/detail',data)//获取订单详情
export const addressList = () => Network.get('/wx/address/list')//收货地址列表
export const regionList = (data) => Network.get('/wx/region/pid',data)// 获取省份三级联动
export const addressSave = (data) => Network.post('/wx/address/save',data)//保存收货地址
export const addressDelete = (data) => Network.post('/wx/address/delete',data)//删除某个收货信息
export const AddressDetail = (data) => Network.get('/wx/address/detail',data)//收货地址详情
export const AuthLogout = () => Network.post('/wx/auth/logout')//账号登出
export const deleteOrder = (data) => Network.post('/wx/order/delete',data)//删除订单
export const cancelOrder = (data) => Network.post('/wx/order/cancel',data)//取消订单
export const SubmitZeroPay = (data) => Network.post('/wx/order/submitZeroPay',data)//零元支付接口
export const PayMoney = (data) => Network.get('/wx/order/paypal/pc',data)//支付接口
export const PaySuc = (data) => Network.get('/wx/order/paypalSuccess',data)//支付成功接口
export const PayFail = (data) => Network.get('/wx/order/paypalError',data)//支付失败接口

//placeOrder.Vue
export const CartCheckout = (data) => Network.get('/wx/cart/checkout',data)//下单前信息确认 
export const orderSubmit = (data) => Network.post('/wx/order/submit',data)//提交订单

//List.Vue
export const CartFastAdd = (data) => Network.post('/wx/cart/fastadd',data)//立即购买商品
export const cartAdd = (data) => Network.post('/wx/cart/add',data)//加入购物车
export const GoodsDetail = (data) => Network.get('/wx/goods/detail',data)//获得商品的详情
export const GetGoods = () => Network.get('/wx/cart/get_hot_goods')//相关商品推荐

//register.vue
export const authRegister = (data) => Network.post('/wx/auth/register',data)//注册账号
export const AuthRegisterCaptcha = (data) => Network.post('/wx/auth/regCaptcha',data)//手機发送验证码
export const AuthRegisterCaptchaA = (data) => Network.post('/wx/auth/mailRegCaptcha',data)//郵箱发送验证码

//cart.vue 
export const CartList = (data) => Network.get('/wx/cart/index',data)//获取购物车数据
export const cartUpdate = (data) => Network.post('/wx/cart/update',data)//更新购物车的商品
export const CartDelete = (data) => Network.post('/wx/cart/delete',data)//删除购物车的商品
export const CartChecked = (data) => Network.post('/wx/cart/checked',data)//选择或取消选择商品

//forget.vue
export const AuthReset = (data) => Network.post('/wx/auth/reset',data)//忘记密码
export const ResetCaptcha = (data) => Network.post('/wx/auth/captcha',data)//忘记密码的发送验证码

//search.vue
export const GoodsList = (data) => Network.get('/wx/goods/list',data)//搜索列表




export function couponSelectList(data) {
    return Network.get('wx/coupon/selectlist', data)
}