<template>
  <div class="Getgoods">
    <sapn class="Getgoods-title">{{
      lang.language == "en-US" ? "Related products" : "相關產品"
    }}</sapn>
    <div class="tiao"></div>
    <div class="Getgoods-container">
      <div
        class="Getgoods-inside"
        v-for="(item, index) in GoodList"
        :key="index"
      >
        <div class="Getgoodsimg">
          <img :src="item.picUrl" />
        </div>
        <div class="goodsTitle">{{ item.name }}</div>
        <!-- <div class="shopNeirong">{{item.brief}}</div> -->
        <div class="goodsTitles">HK${{ item.retailPrice }}</div>
        <el-button @click="buy(item.id)">{{ i18n.purchase }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getLocalStorage } from "@/utils/local-storage";
export default {
  data() {
    return {
      lang: "",
    };
  },
  created() {
    this.lang = getLocalStorage("language");
  },
  computed: {
    i18n() {
      return this.$t("index");
    },
  },
  methods: {
    buy(id) {
      this.$emit("buy", id);
    },
  },
  props: {
    title: {
      type: String,
    },
    GoodList: {
      type: Array,
    },
  },
};
</script>

<style lang="scss">
@import "../common/style";
.Getgoods {
  width: 75%;
  background-color: white;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  .Getgoods-title {
    width: 20%;
    height: 60px;
    //background-color: green;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 27px;
    margin-top: 30px;
  }

  .tiao {
    width: 5%;
    height: 2px;
    background-color: $neirongColor;
  }

  .Getgoods-container {
    margin-top: 30px;
    width: 90%;
    height: 350px;
    //background-color: yellow;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .Getgoods-inside {
      width: 20%;
      height: 290px;
      //background-color: green;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .Getgoodsimg {
        width: 120px;
        height: 130px;

        //background-color: pink;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .goodsTitle {
        font-weight: 400;
        font-size: 14px;
        color: $titleColor;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 170px;
      }

      .shopNeirong {
        font-weight: 400;
        font-size: 13px;
        color: #7d7e80;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 200px;
      }

      .goodsTitles {
        font-weight: 700;
        font-size: 17px;
        color: $priceColor;
      }

      .el-button {
        border: 1px solid $neirongColor;
        color: black;
        background-color: white;
      }

      .el-button:hover {
        border: 1px solid $neirongColor;
        color: white;
        background-color: $neirongColor;
      }
    }
  }
}
</style>
