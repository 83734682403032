<template>
	<div>
		<Title :TitleName="TitleName"></Title>
		<el-menu active-text-color="#FF974B" :default-active="activeIndex" class="el-menu-inside" mode="horizontal">
			<el-menu-item @click="changeOrder(item.id)" v-for="item in tab" :key="item.id" :index="item.id">{{lang.language == 'en-US' ? item.ENname : item.name}}</el-menu-item>
		</el-menu>
		<div class="order_container">
			<el-table @cell-click="jump" :data="tableData" height="700" border style="width: 100%">
				<el-table-column prop="orderSn" :label="i18n.Ordernumber" width="180">
				</el-table-column>
				<el-table-column prop="actualPrice" :label="i18n.Total" width="180">
				</el-table-column>
				<el-table-column prop="createTime" :label="i18n.date">
				</el-table-column>
				<el-table-column prop="orderStatusText" :label="i18n.state">
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import {
		getLocalStorage
	} from '@/utils/local-storage';
	import {
		OrderList
	} from '../../../api/index.js'
	import Title from '../../../components/title.vue'
	export default {
		computed: {
			i18n() {
				return this.$t('index')
			}
		},
		created() {
			this.lang = getLocalStorage('language')
			OrderList({
				showType: 0,
				page: 0,
				limit: 10
			}).then(res => {
				console.log(res)
				this.tableData = res.data.list
				console.log(this.tableData)
			})
		},
		components: {
			Title
		},
		data() {
			return {
				lang:'',
				activeIndex:'0',
				TitleName: '我的訂單',
				tableData: [],
				tab:[
					{
						ENname:'All',
						name:'全部',
						id:'0'
					},
					{
						ENname:'To be paid',
						name:'待支付',
						id:'1'
					},
					{
						ENname:'Paid',
						name:'已支付',
						id:'2'
					},
					{
						ENname:'Cancelled',
						name:'已取消',
						id:'3'
					}
				]
			}
		},
		methods: {
			changeOrder(id){
				OrderList({
					showType: Number(id),
					page: 0,
					limit: 10
				}).then(res => {
					console.log(res)
					this.tableData = res.data.list
					console.log(this.tableData)
				})
			},
			jump(row) {
				this.$router.push({
					path: '/userInfo/orderDetail',
					query: {
						id: row.id,
						orderSn: row.orderSn
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	.el-menu-inside{
		display: flex;
		justify-content: space-around;
		.el-menu-item{
			width: 300px;
			padding: 0px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.order_container {
		width: 94%;
		// height: 30px;
		// background-color: red;
		padding: 30px;

		.el-table {
			.el-table__row {
				.el-table_1_column_1 {
					color: blue;
					cursor: pointer;
				}
			}
		}
	}
</style>
