<template>
	<div class="home-container">
		<div class="home-swiper">
			<el-carousel trigger="click" height="500px" style="border-radius: 10px;">
				<el-carousel-item v-for="(item,index) in homeinfo.banner" :key="index">
					<el-image :src="item.url" fit="fill" />
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="many-shop">
			<div class="hot-shop-title">
				<div class="xaintiao"></div>
				<div class="newxaintiao">{{i18n.Newproductlaunch}}</div>
				<div class="xaintiao"></div>
			</div>
			<div class="many-shop-inside">
				<div class="hot-shop-inside" @click="jumpList(item.id)" v-for="(item,index) in homeinfo.newGoodsList"
					:key="index">
					<div class="header">
						<el-image lazy :src="item.picUrl" />
					</div>
					<h6>{{item.name}}</h6>
					<h3>{{item.brief}}</h3>
					<p>HK${{item.retailPrice}}.00</p>
				</div>
			</div>
		</div>
		<div class="many-shop">
			<div class="hot-shop-title">
				<div class="xaintiao"></div>
				<div :class="[lang.language == 'en-US' ? 'newxaintiao2' : 'newxaintiao']">{{i18n.Popularrecommendation}}</div>
				<div class="xaintiao"></div>
			</div>
			<div class="many-shop-inside">
				<div class="hot-shop-inside" @click="jumpList(item.id)" v-for="(item,index) in homeinfo.hotGoodsList"
					:key="index">
					<div class="header">
						<el-image lazy :src="item.picUrl" />
					</div>
					<h6>{{item.name}}</h6>
					<h3>{{item.brief}}</h3>
					<p>HK${{item.retailPrice}}.00</p>
				</div>
			</div>
		</div>
		<Foot></Foot>
	</div>
</template>

<script>
	import {
		getLocalStorage
	} from '@/utils/local-storage';
	import Foot from '../../components/foot.vue'
	import {
		getHome
	} from '../../api/index.js'
	export default {
		computed: {
			i18n() {
				return this.$t('index')
			}
		},
		components:{
			Foot
		},
		created() {
			this.lang = getLocalStorage('language')
			getHome().then(res => {
				this.homeinfo = res.data
				console.log(this.homeinfo)
			})
		},
		data() {
			return {
				homeinfo: [],
				lang:''
			}
		},
		methods: {
			jumpList(id) {
				this.$router.push({
					path: '/List',
					query: {
						id
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	@import '../../common/style';
	.home-container {
		background: #f8f8fa;
		padding-top: 145px;
		padding-bottom: 50px;

		.home-swiper {
			width: 100%;
			height: 455px;
			margin: auto;

			.el-image {
				width: 100%;
				height: 100%;
			}
		}

		.many-shop {
			margin: auto;
			margin-top: 120px;
			width: 1220px;
			height: 922px;

			//background-color: green;
			.hot-shop-title {
				height: 60px;
				width: 96.5%;
				background: white;
				display: flex;
				align-items: center;
				color: #333;
				justify-content: center;
				align-items: center;
				padding-top: 50px;

				.xaintiao {
					height: 2px;
					width: 400px;
					background-color: #333;
				}

				.newxaintiao {
					font-size: 30px;
					margin-left: 30px;
					margin-right: 30px;
				}
				.newxaintiao2 {
					font-size: 30px;
					margin-left: 5px;
					margin-right: 5px;
				}
			}

			.many-shop-inside {
				display: flex;
				flex-wrap: wrap;
				width: 97.5%;
				height: 860px;

				//background-color: orchid;
				.hot-shop-inside:hover {
					background-color: #F5F5F5;
					transition: all 0.25s ease-in-out;
				}

				.hot-shop-inside {
					width: 33%;
					height: 430px;
					background-color: white;
					cursor: pointer;

					.header {
						width: 100%;
						height: 260px;
						//background-color: hotpink;
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							width: 206px;
							height: 206px;
						}
					}

					h6 {
						margin-bottom: 0px;
						margin-top: 0px;
						font-weight: 400;
						font-size: 16px;
						color: $titleColor;
						text-align: center;
					}

					h3 {
						text-align: center;
						font-weight: 400;
						font-size: 13px;
						color: #7D7E80;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						margin-left: 30px;
						margin-right: 30px;
					}

					p {
						text-align: center;
						margin-top: 20px;
						color: $priceColor;
						font-size: 17px;
						font-weight: 700;
					}

					.bths {
						width: 100%;
						height: 70px;
						display: flex;
						align-items: center;
						justify-content: center;

						//background-color: orange;
						button {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 100px;
							height: 30px;
							border-radius: 4px;
							font-size: 12px;
						}
					}
				}
			}
		}
	}
</style>
