<template>
  <div>
    <Title :TitleName="TitleName" @add="lookForm" :isbtn="isbtn"></Title>
    <div class="tables">
      <div class="tables_header">
        <span style="margin-left: 38px">{{ i18n.name }}</span>
        <span
          :style="{
            'margin-left': lang.language == 'en-US' ? '140px' : '190px',
          }"
          >{{ i18n.Detailedaddress }}</span
        >
        <span
          :style="{
            'margin-left': lang.language == 'en-US' ? '160px' : '220px',
          }"
          >{{ i18n.Telephone }}</span
        >
      </div>
      <div class="tables_container">
        <div class="table_nodata" v-if="address.length == 0">
          <p>{{ i18n.addressyet }}</p>
          <el-button @click="lookForm">{{ i18n.Addaddress }}</el-button>
        </div>
        <div v-else>
          <el-scrollbar style="height: 890px">
            <div
              class="tables_inside"
              v-for="(item, index) in address"
              :key="index"
            >
              <span style="width: 106px; text-align: center; font-size: 14px">{{
                item.name
              }}</span>
              <span style="flex: 0.8; text-align: center; font-size: 14px">{{
                item.country +
                item.province +
                item.city +
                " " +
                item.addressDetail
              }}</span>
              <span style="width: 160px; text-align: center; font-size: 14px">{{
                item.tel
              }}</span>
              <!-- <span class="moreng">设为默认</span> -->
              <el-button
                class="primary"
                @click="updata(item.id)"
                style="margin-right: 10px"
                type="primary"
                >{{ i18n.modify }}</el-button
              >
              <el-button
                class="danger"
                type="danger"
                @click="deletes(item.id)"
                >{{ i18n.Delete }}</el-button
              >
              <el-button
                class="primary"
                type="primary"
                @click="userAddress(item.id)"
                >{{ i18n.Usethisaddress }}</el-button
              >
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>

    <!-- 新增收货地址的弹出框 -->
    <el-dialog
      :title="i18n.Newreceivingaddress"
      :visible.sync="centerDialogVisible"
      width="23.5%"
      center
    >
      <el-input
        style="margin-bottom: 10px"
        :placeholder="i18n.ConsigneeName"
        v-model="info.name"
      >
      </el-input>
      <el-input
        @blur="yanzheng"
        type="number"
        style="margin-bottom: 10px"
        :placeholder="i18n.Mobilenumber"
        v-model="info.tel"
      >
      </el-input>
      <el-select
        v-model="info.country"
        @change="select"
        style="margin-bottom: 10px"
        :placeholder="i18n.Pleaseregion"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        v-show="isShow"
        v-model="info.province"
        @change="select2"
        style="margin-bottom: 10px"
        :placeholder="i18n.PleaseselectProvince"
      >
        <el-option
          v-for="item in options2"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        v-show="isShow"
        v-model="info.city"
        @change="select3"
        style="margin-bottom: 10px"
        :placeholder="i18n.Pleaseselectarea"
      >
        <el-option
          v-for="item in options3"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-input
        style="margin-bottom: 10px"
        :placeholder="i18n.Receivingaddress"
        v-model="info.addressDetail"
      >
      </el-input>
      <br />
      <br />
      <div style="display: flex; width: 100%; justify-content: center">
        <el-button
          type="primary"
          style="width: 425px; height: 50px"
          @click="addInfo"
          >{{ i18n.preservation }}</el-button
        >
      </div>
    </el-dialog>

    <!-- 修改收货地址的弹出框 -->
    <el-dialog
      :title="i18n.Editshippingaddress"
      :visible.sync="centerDialogVisible2"
      width="30%"
      center
    >
      <el-input
        style="margin-bottom: 10px"
        :placeholder="i18n.ConsigneeName"
        v-model="info2.name"
      >
      </el-input>
      <el-input
        @blur="yanzheng2"
        type="number"
        style="margin-bottom: 10px"
        :placeholder="i18n.Mobilenumber"
        v-model="info2.tel"
      >
      </el-input>
      <el-select
        v-model="info2.country"
        @change="XiuGaiselect"
        style="margin-bottom: 10px"
        :placeholder="i18n.Pleaseregion"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        v-show="isShow2"
        v-model="info2.province"
        @change="XiuGaiselect2"
        style="margin-bottom: 10px"
        :placeholder="i18n.PleaseselectProvince"
      >
        <el-option
          v-for="item in options2"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        v-show="isShow2"
        v-model="info2.city"
        @change="XiuGaiselect3"
        style="margin-bottom: 10px"
        :placeholder="i18n.Pleaseselectarea"
      >
        <el-option
          v-for="item in options3"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-input
        style="margin-bottom: 10px"
        :placeholder="i18n.Receivingaddress"
        v-model="info2.addressDetail"
      >
      </el-input>
      <br />
      <br />
      <div style="display: flex; width: 100%; justify-content: center">
        <el-button
          class="btns2"
          type="primary"
          style="width: 425px; height: 50px"
          @click="addInfo2"
          >{{ i18n.preservation }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { setLocalStorage, getLocalStorage } from "@/utils/local-storage";
import {
  addressList,
  addressSave,
  regionList,
  addressDelete,
  AddressDetail,
} from "../../../api/index.js";
import Title from "../../../components/title.vue";
export default {
  computed: {
    i18n() {
      return this.$t("index");
    },
  },
  created() {
    this.lang = getLocalStorage("language");
    addressList().then((res) => {
      this.address = res.data.list;
    });
    regionList({
      pid: -100,
    }).then((res) => {
      this.options = res.data;
    });
  },
  components: {
    Title,
  },
  methods: {
    yanzheng2() {
      let num = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
      if (!num.test(this.info2.tel)) {
        this.$notify.error({
          title: this.i18n.Pleasephonnumber,
        });
      }
    },
    yanzheng() {
      //   let num = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
      //   if (!num.test(this.info.tel)) {
      //     this.$notify.error({
      //       title: this.i18n.Pleasephonnumber,
      //     });
      //   }
    },
    deletes(id) {
      addressDelete({
        id: id,
      }).then((res) => {
        if (res.errno == 0) {
          this.$notify({
            title: this.i18n.Successfullydeleted,
            type: "success",
          });
          addressList().then((res) => {
            this.address = res.data.list;
          });
        } else {
          this.$notify.error({
            title: res.errmsg,
          });
        }
      });
    },
    userAddress(id) {
      setLocalStorage({
        AddressId: id,
      });
      this.$router.push({
        path: "/placeOrder",
      });
    },
    updata(id) {
      AddressDetail({
        id,
      }).then((res) => {
        this.centerDialogVisible2 = true;
        this.info2 = res.data;
        console.log(this.info2);
        if (
          this.info2.country == "中国澳门" ||
          this.info2.country == "中国香港" ||
          this.info2.country == "海外"
        ) {
          this.isShow2 = false;
        }
        // if(res.data.country != '中國內地'){
        // 	this.isShow2 = false
        // }else{
        // 	console.log(11111)
        // }
      });
    },
    lookForm() {
      this.centerDialogVisible = true;
      this.info.name = "";
      this.info.tel = "";
      this.info.addressDetail = "";
      this.info.province = "";
      this.info.country = "";
      this.info.city = "";
    },
    addInfo() {
      this.yanzheng();
      console.log(this.info);
      addressSave(this.info).then((res) => {
        if (res.errno == 0) {
          this.$notify({
            title: this.i18n.Addaddresssuccessfully,
            type: "success",
          });
          this.centerDialogVisible = false;
          addressList().then((res) => {
            this.address = res.data.list;
          });
        } else {
          this.$notify.error({
            title: res.errmsg,
          });
        }
      });
    },
    addInfo2() {
      this.yanzheng2();
      console.log(this.info2);
      addressSave(this.info2).then((res) => {
        if (res.errno == 0) {
          this.$notify({
            title: this.i18n.Addressmodifiedsuccessfully,
            type: "success",
          });
          this.centerDialogVisible2 = false;
          addressList().then((res) => {
            this.address = res.data.list;
          });
        } else {
          this.$notify.error({
            title: res.errmsg,
          });
        }
      });
    },
    XiuGaiselect(e) {
      if (e != -3) {
        this.isShow2 = false;
        let selectedName = {};
        selectedName = this.options.find((item) => {
          return item.id === e;
        });
        this.info2.country = selectedName.name;
        console.log(this.info2.country);
        this.info2.province = "";
        this.info2.city = "";
      } else {
        this.isShow2 = true;
        regionList({
          pid: e,
        }).then((res) => {
          console.log(res);
          this.options2 = res.data;
        });
        let selectedName = {};
        selectedName = this.options.find((item) => {
          return item.id === e;
        });
        this.info2.country = selectedName.name;
        console.log(this.info2.country);
        this.info2.province = "";
        this.info2.city = "";
      }
    },
    XiuGaiselect2(e) {
      regionList({
        pid: e,
      }).then((res) => {
        this.options3 = res.data;
      });
      let selectedName = {};
      selectedName = this.options2.find((item) => {
        return item.id === e;
      });
      this.info2.province = selectedName.name;
      console.log(this.info2.province);
      this.info2.city = "";
    },
    XiuGaiselect3(e) {
      let selectedName = {};
      selectedName = this.options3.find((item) => {
        return item.id === e;
      });
      this.info2.city = selectedName.name;
      console.log(this.info2.city);
    },
    select(e) {
      if (e != -3) {
        this.isShow = false;
        let selectedName = {};
        selectedName = this.options.find((item) => {
          return item.id === e;
        });
        this.info.country = selectedName.name;
        console.log(this.info.country);
        this.info.province = "";
        this.info.city = "";
      } else {
        this.isShow = true;
        regionList({
          pid: e,
        }).then((res) => {
          console.log(res);
          this.options2 = res.data;
        });
        let selectedName = {};
        selectedName = this.options.find((item) => {
          return item.id === e;
        });
        this.info.country = selectedName.name;
        console.log(this.info.country);
        this.info.province = "";
        this.info.city = "";
      }
    },
    select2(e) {
      regionList({
        pid: e,
      }).then((res) => {
        this.options3 = res.data;
      });
      let selectedName = {};
      selectedName = this.options2.find((item) => {
        return item.id === e;
      });
      this.info.province = selectedName.name;
      console.log(this.info.province);
      this.info.city = "";
    },
    select3(e) {
      let selectedName = {};
      selectedName = this.options3.find((item) => {
        return item.id === e;
      });
      this.info.city = selectedName.name;
      console.log(this.info.city);
    },
  },
  data() {
    return {
      lang: "",
      info2: {},
      isShow: true,
      isShow2: true,
      options: [],
      options2: [],
      options3: [],
      info: {
        addressDetail: "", //详细地址
        city: "", //区
        country: "", //省
        isDefault: false,
        name: "", //姓名
        province: "", //市
        tel: "", //电话号码
      },
      centerDialogVisible2: false,
      centerDialogVisible: false,
      TitleName: "收貨地址",
      isbtn: true,
      address: [],
    };
  },
};
</script>

<style lang="scss">
@import "../../../common/style.scss";
.tables {
  width: 100%;

  .tables_header {
    height: 38px;
    width: 100%;
    font-size: 12px;
    background: #eee;
    border-bottom: 1px solid #dbdbdb;
    border-top: 1px solid #dbdbdb;
    display: flex;
    align-items: center;

    span {
      color: #838383;
    }
  }

  .tables_container {
    width: 100%;

    .table_nodata {
      width: 100%;
      height: 200px;
      //background-color: red;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        font-size: 20px;
        color: #666;
      }

      button {
        width: 100px;
        height: 30px;
        padding: 0px;
        font-size: 12px;
      }
    }

    .tables_inside {
      width: 100%;
      height: 115px;
      display: flex;
      align-items: center;
      border-top: 1px solid #cfcfcf;

      .danger {
        border-color: #f56c6c;
        background-color: white;
        color: black;
      }
      .danger:hover {
        border-color: #f56c6c;
        background-color: #f56c6c;
        color: white;
      }
      .primary {
        border-color: $neirongColor;
        background-color: white;
        color: black;
      }
      .primary:hover {
        border-color: $neirongColor;
        background-color: $neirongColor;
        color: white;
      }
    }
  }
}
.el-dialog {
  .el-button {
    border: 1px solid $neirongColor;
    color: black;
    background-color: white;
  }
  .el-button:hover {
    border: 1px solid $neirongColor;
    color: white;
    background-color: $neirongColor;
  }
}

.el-select {
  width: 400px;
}
</style>
