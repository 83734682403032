import Vue from 'vue'
import Vuex from 'vuex'
import { GetL2 } from '../api/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		productList:[],
		insideListColorId:0,
  },
  mutations: {
		clickProduct(state,res){
			state.productList = res.data
			console.log(state.productList)
		},
		change(state,id){
			state.insideListColorId = id
		}
  },
  actions: {
		clickProduct(context,id){
			GetL2({id}).then(res => {
				context.commit('clickProduct',res)
			})
		}
  },
  modules: {
  }
})
