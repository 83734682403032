<template>
	<div class="paySuc-container">
		<div class="header">
			<div class="imgae">
				<img src="../../assets/fail.png" />
			</div>
			<div class="text">{{i18n.Paymentfailure}}</div>
			<div class="btn">
				<el-button @click="jump">{{i18n.Returnorder}}</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import { PayFail } from '../../api/index.js'
	export default{
		computed: {
			i18n() {
				return this.$t('index')
			}
		},
		created() {
			PayFail({paymentId:this.$route.query.paymentId,PayerID:this.$route.query.PayerID}).then(res => {
				console.log(res)
				console.log('这是支付失败的接口' + res)
			})
		},
		data() {
			return{
				
			}
		},
		methods:{
			jump(){
				this.$router.push({
					path:'/placeOrder'
				})
			}
		}
	}
</script>

<style lang="scss">
	@import '../../common/style';
	.paySuc-container{
		padding-top: 100px;
		width: 99vw;
		height: 400px;
		//background-color: red;
		.header{
			width: 100%;
			height: 350px;
			//background-color: blue;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.imgae{
				width: 100px;
				height: 100px;
				//background-color: yellow;
				img{
					width: 100%;
					height: 100%;
				}
			}
			.text{
				color: #cdcdcd;
				font-size: 20px;
				margin-top: 20px;
			}
			.btn{
				width: 30%;
				height: 100px;
				//background-color: green;
				margin-top: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				.el-button {
					border: 1px solid $neirongColor;
					color: black;
					background-color: white;
					width: 130px;
				}
				
				.el-button:hover {
					border: 1px solid $neirongColor;
					color: white;
					background-color: $neirongColor;
				}
			}
		}
	}
</style>
