<template>
	<div class="titles">
		<div style="width: 220px;">{{TitleName}}</div>
		<el-button @click="add" v-if="isbtn == true">{{i18n.Addshippingaddress}}</el-button>
	</div>
</template>

<script>
	export default{
		props:['TitleName','isbtn'],
		computed: {
			i18n() {
				return this.$t('index')
			}
		},
		methods:{
			add(){
				this.$emit('add')
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.titles{
		height: 60px;
		width: 97%;
		background: #f3f3f3;
		display: flex;
		align-items: center;
		font-size: 18px;
		color: #333;
		padding-left: 30px;
		border-radius: 8px 8px 0 0;
		box-shadow: 0 1px 7px #d4d4d4;
		button{
			width: 130px;
			height: 30px;
			font-size: 12px;
			color: #646464;
			padding: 0px;
			margin-left: 730px;
		}
	}
</style>
