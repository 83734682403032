export default {
	index: {
		//header.vue
		homePage: '首頁',
		Pleaseentertheproductname: '請輸入商品名稱',
		Allproducts: '全部商品',
		ShoppingCart: '購物車',
		personalinformation: '個人信息',
		Contact: '聯絡我們',
		Chooselanguage: '選擇語言',
		Pleaseenterthecontent: '請輸入內容',

		//home.vue
		Popularrecommendation: '人氣推薦',
		Newproductlaunch: '新品首發',

		//foot.vue
		Focusonus: '關注我們',
		Aboutus: '關於我們',
		Companyinformation: '公司位置',
		Termsofuse: '使用條款',
		Privacypolicy: '隱私政策',
		Informationinquiry: '資料查詢',
		Frequentlyaskedquestions: '常見問題',
		Aboutdelivery: '關於送貨',
		Aboutreturns: '關於退貨',
		Ordertracking: '訂單追踨',

		//Detail.vue
		Noproductsonsale: '無上架商品',
		purchase: '購買',

		//List.vue
		number: '數量',
		addtocart: '加入購物車',
		Buynow: '現在購買',
		productdetails: '產品詳情',
		Nodetails: '無詳情',
		Relatedproducts: '相關產品',
		Addedtocart: '已加入購物車',

		//placeOrder.vue
		Confirmclosing: '確認結帳',
		Totalcommodities: '商品合計',
		Freight: '運費',
		Taxation: '稅費',
		Preferentialamount: '優惠金額',
		Ordernotes: '訂單備註',
		Pleaseinputthecontent: '请输入内容',
		Orderamount: '訂單金額',
		Actualamountpayable: '實際應付金額',
		Payimmediately: '立刻支付',
		possible: '暫無收貨地址噢，趕快去添加吧',
		Receivinginformation: '收货信息',
		name: '姓名',
		contactnumber: '联系电话',
		Detailedaddress: '详细地址',
		Changeaddress: '修改地址',
		first: '沒有選擇商品,請先選擇商品',
		address: '收货地址',

		//payment.vue
		Ordernumber: '訂單編號',
		Amountactuallypaid: '實付金額',
		Submit: '提交',

		//Cart.vue
		Selectall: '全选',
		Shoppinglist: '購物清單',
		Yourshoppingcartisempty: '你的購物車空空如也',
		Productinformation: '商品資訊',
		Unitprice: '單價',
		operation: '操作',
		Total: '合計',
		settlement: '現在結算',
		anyproducts: '您沒選擇任何商品',


		//payFail.vue
		Paymentfailure: '支付失敗',
		Returnorder: '返回訂單',

		//title.vue
		Addshippingaddress: '添加收貨地址',

		//userinfo.vue
		Myaccount: '我的帳戶',
		Accountinformation: '帳戶資料',
		Myorder: '我的訂單',
		Receivingaddress: '收貨地址',

		//AccountInformation.vue
		Membernumber: '會員編號',
		AchievementTitle: '會員級別',
		Gender: '性別',
		Birthday: '生日',
		ChineseName: '中文姓名',
		Registrationarea: '登記區域',
		Mobilenumber: '手機號碼',
		arearnumber: '國際區號',
		Email: '電子郵箱',
		Introducernumber: '介紹人號碼',
		Introducer: '介紹人',
		notification: '接受通知方式',
		account: '退出當前帳號',

		//myOrder.vue
		date: '日期',
		state: '狀態',

		//oderDetail.vue
		usecode: '使用折扣碼',
		Voucher: '使用電子購物券',
		deleteorder: "刪除此訂單",
		cancelorder: '取消訂單',
		pay: '支付',
		Deleteorder: '是否刪除此訂單',
		Tips: '提示',
		Confirm: '確定',
		cancel: '取消',
		Successfullydeleted: "刪除成功",
		Deletioncanceled: '已取消刪除',
		Doyouwanttocancelorder: '是否取消此訂單',
		Cancelsuccessfully: '取消成功',
		Cancelled: '已取消',

		//HarvestAddress.vue
		Telephone: '電話號碼',
		addressyet: '你還未添加收貨地址',
		Addaddress: '添加地址',
		modify: '修改',
		Delete: '删除',
		Usethisaddress: '使用此地址',
		Newreceivingaddress: '新增收貨地址',
		ConsigneeName: '收貨人姓名',
		Pleaseregion: '請選擇地區',
		PleaseselectProvince: '請選擇省份',
		Pleaseselectarea: '請選擇區域',
		preservation: "保存",
		Editshippingaddress: '編輯收貨地址',
		Pleasephonnumber: '請輸入正確的手機號噢!',
		Addaddresssuccessfully: '添加地址成功',
		Addressmodifiedsuccessfully: '修改地址成功',




		//login.vue
		Logaccount: '使用帳號登入網上商城',
		Accountnumber: '帳號',
		password: '密碼',
		Registeredaccountnumber: '註冊帳號',
		Forgetthepassword: '忘記密碼',
		login: '登入',
		Return: '返回',

		//forget.vue
		Findthepassword: '找回密碼',
		Verificationcode: '驗證碼',
		Sendverificationcode: '發送郵箱驗證碼',
		Sendmobilecode: '發送手機號驗證碼',
		Verificationcodesentsuccessfully: '驗證碼發送成功',
		SMS: '密碼修改成功',
		blank: '有未填寫的地方，請檢查',
		UseEmail: '郵件驗證',
		UseSMS: '短信驗證',
		newPassword: '新密碼',

		//register.vue
		newaccount: '註冊新賬號',
		Sponsorsname: '介紹人姓名',
		ChineseEnglishsurname: '中文/英文姓',
		ChineseEnglishname: '中文/英文名',
		Pleaseselectactivearea: '請選擇活動區域',
		male: '男',
		female: '女',
		Selectadate: '選擇日期',
		register: '注册',
		mobilephonenumber: '請輸入手機號',
		registrationarea: '請輸入登記區域',
		loginwassuccessful: '注册成功',
		yourmobilephone: '已把密碼發送至您的手機',
		isanyblank: '有未填寫的地方，請檢查'
	}
}