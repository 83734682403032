import en from './en-US.js'
import hk from './zh-HK.js'

export default {
	'en-US': {
		index: en.index
	},
	'zh-HK':{
		index: hk.index
	}
}
