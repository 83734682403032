/*
 * @Author: Zzy
 * @Date: 2021-07-19 10:10:28
 * @LastEditTime: 2021-07-19 15:07:19
 * @LastEditors: Zzy
 * @Description: 
 * @FilePath: /hfs-mall-pc/src/api/config.js
 * Creat by Zzy
 */
let baseUrl = ""

if (process.env.NODE_ENV === 'development') {
	// 开发环境
	//baseUrl = 'https://jianning.cn1.utools.club'
	baseUrl = 'https://puremehkeshop.com/'
	// baseUrl = 'http://hkmall-pc.starlets.com.cn/'

} else {
	// 生产环境
	baseUrl = 'https://puremehkeshop.com/'
	// baseUrl = 'http://hkmall-pc.starlets.com.cn/'
}

export default baseUrl