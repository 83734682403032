<template>
  <div class="paymentContainer">
    <div class="header">
      請在<span style="color: red">1天內</span>完成付款，否則系統自動取消訂單
    </div>
    <div class="inside">
      <div class="insides">
        <span>{{ i18n.Ordernumber }}</span>
        <span>{{ order }}</span>
      </div>
      <div class="insides">
        <span>{{ i18n.Amountactuallypaid }}</span>
        <span>HK${{ money }}</span>
      </div>
    </div>
    <div class="bottom">
      <el-button @click="pay">{{ i18n.Submit }}</el-button>
    </div>
  </div>
</template>

<script>
import { OrderDetail, PayMoney } from "../../api/index.js";
export default {
  computed: {
    i18n() {
      return this.$t("index");
    },
  },
  created() {
    OrderDetail({ orderId: this.$route.query.orderId }).then((res) => {
      this.order = res.data.orderInfo.orderSn;
      this.money = res.data.orderInfo.actualPrice;
    });
  },
  data() {
    return {
      order: null,
      money: null,
    };
  },
  methods: {
    pay() {
      PayMoney({ orderId: this.$route.query.orderId }).then((res) => {
        console.log(res);
        window.location.href = res.data.href;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../common/style";
.paymentContainer {
  width: 98vw;
  height: 570px;
  background-color: #f2f2f2;
  padding-top: 140px;
  //   padding-top: 140px;
  .header {
    width: 100%;
    // background-color: #fffeec;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 10px;
  }
  .inside {
    width: 70%;
    height: 100px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    .insides {
      height: 50%;
      display: flex;
      width: 50%;
      //background-color: green;
      align-items: center;
      justify-content: space-between;
    }
  }
  .bottom {
    margin-top: 100px;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-button {
      border: 1px solid $neirongColor;
      color: black;
      background-color: white;
    }

    .el-button:hover {
      border: 1px solid $neirongColor;
      color: white;
      background-color: $neirongColor;
    }
  }
}
</style>
