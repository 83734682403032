<template>
  <div class="userInfo_container">
    <div class="userInfo_inside">
      <div class="userInfo_left">
        <div class="userInfo_header">
          <h5>{{ i18n.Myaccount }}</h5>
        </div>
        <div class="menu">
          <el-menu
            router
            text-color="black"
            active-text-color="#FFFFFF"
            :default-active="$route.path"
          >
            <el-menu-item index="/userInfo/AccountInformation">{{
              i18n.Accountinformation
            }}</el-menu-item>
            <el-menu-item index="/userInfo/MyOrder">{{
              i18n.Myorder
            }}</el-menu-item>
            <el-menu-item index="/userInfo/HarvestAddress">{{
              i18n.Receivingaddress
            }}</el-menu-item>
          </el-menu>
        </div>
      </div>
      <div class="userInfo_right">
        <router-view :info="info" :qrcode="qrcode"></router-view>
      </div>
    </div>
  </div>
</template>



<script>
import { UserDetail,getTwoCode } from "../../api/index.js";
export default {
  created() {
    UserDetail().then((res) => {
      if (res.errno == 0) {
        console.log(res);
        this.info = res.data.detail;
        console.log(this.info);
      } else if (res.errmsg == "請登錄") {
        this.$notify.error({
          title: res.errmsg,
        });
        setTimeout(() => {
          this.$router.push({
            path: "/Login",
          });
        }, 2500);
      } else {
        this.$notify.error({
          title: res.errmsg,
        });
      }
    });
    getTwoCode().then((res) => {
      console.log(res.errmsg,'aaaaaaaaa')
      if (res.errno == 0) {
        console.log(res);
        this.qrcode = res.errmsg;
        console.log(this.info);
      } 
      console.log(this.info,'===========')
    })
    // console.log(window.localStorage.getItem('Authorization'),'Authorization')
  },
  data() {
    return {
      info: {},
      qrcode: '',
    };
  },
  computed: {
    i18n() {
      return this.$t("index");
    },
  },
};
</script>

<style lang="scss">
@import "../../common/style";
.userInfo_container {
  width: 98vw;
  //height: 100vh;
  padding-top: 150px;
  background: #ededed;
  .userInfo_inside {
    width: 1220px;
    height: 1027px;
    margin: auto;
    //background-color: yellow;
    display: flex;
    justify-content: space-between;
    .userInfo_left {
      width: 210px;
      height: 600px;
      // background-color: orange;
      .userInfo_header {
        width: 100%;
        height: 100px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        h5 {
          margin-top: 0px;
          margin-bottom: 0px;
          font-size: 25px;
          font-weight: 700;
          color: #666;
        }
      }
      .menu {
        width: 100%;
        li {
          display: flex;
          justify-content: center;
          border-top: 1px solid #ebebeb;
        }
        .el-menu-item.is-active {
          background-color: $neirongColor;
        }
      }
    }
    .userInfo_right {
      width: 990px;
      height: 987px;
      border-radius: 8px;
      border: 1px solid #dcdcdc;
      border-color: rgba(0, 0, 0, 0.14);
      background: #fff;
      box-shadow: 5px 5px 5px #dadada;
    }
  }
}
</style>
