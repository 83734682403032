import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueI18n from 'vue-i18n';
import messages from './lang/index.js';

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueI18n)

const i18n = new VueI18n({  
  locale: 'zh-HK',  
  messages
})  


new Vue({
	i18n,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
