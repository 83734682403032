<template>
  <div>
    <Header></Header>
    <router-view></router-view>
  </div>
</template>

<script>
	import {
		getLocalStorage
	} from '@/utils/local-storage';
  import Header from "@/components/header";
  export default {
    name: "App",
    components: {
      Header,
    },
		created() {
			const lang = getLocalStorage('language')
			if(lang.language == 'en-US'){
				console.log('英文')
				this._i18n.locale = 'en-US'
			}else{
				console.log('中文')
				this._i18n.locale = 'zh-HK'
			}
		}
  };
</script>

<style lang="scss">

</style>
