<template>
	<div class="container">
		<div class="inside">
			<!-- 头部 -->
			<div class="header">
				<h4 class="title">
					{{i18n.Logaccount}}
				</h4>
			</div>
			<!-- 内容 -->
			<div class="contact">
				<div class="account_password">
					<el-input @keyup.enter.native="login" @input="accountInput" class="account_input" v-model="account" :placeholder="i18n.Accountnumber"></el-input>
					<el-input @keyup.enter.native="login" @input="accountInput" class="password_input" :placeholder="i18n.password" v-model="password"
						show-password></el-input>
				</div>
				<div :style="{'padding-left' : (lang.language == 'en-US' ? '100px' : '500px')}" class="Remember_password">
					<!-- <el-checkbox v-model="passwordChecked">记住密码</el-checkbox> -->
					<div>
						<span style="cursor: pointer;" class="zhuche" @click="$router.push({path:'/Register'})">
							<a>{{i18n.Registeredaccountnumber}}</a>
						</span>
						<span style="color: #ccc;">|</span>
						<span class="zhuche" @click="forget">
							<a>{{i18n.Forgetthepassword}}</a>
						</span>
					</div>
				</div>

				
				<div class="btns">
					<el-button @click="login" :disabled="btnLoginDisabled" class="btn_login" :type="buttonType">{{i18n.login}}
					</el-button>
					<el-button @click="returns" class="btn_return">{{i18n.Return}}</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		setLocalStorage,
		getLocalStorage
	} from '../../utils/local-storage.js'
	import {
		AuthLoginByAccount
	} from '../../api/index.js'
	export default {
		computed: {
			i18n() {
				return this.$t('index')
			}
		},
		created() {
			this.lang = getLocalStorage('language')
		},
		data() {
			return {
				account: '',
				password: '',
				passwordChecked: false,
				btnLoginDisabled: true,
				buttonType: 'info',
				userInfo: {},
				lang:''
			}
		},
		methods: {
			forget(){
				this.$router.push({
					path:'/Forget'
				})
			},
			returns(){
				this.$router.push({
					path:'/'
				})
			},
			accountInput() {
				if (this.account == '' || this.password == '' || this.account == null || this.password == null || this
					.account == undefined || this.password == undefined) {
					this.btnLoginDisabled = true
					this.buttonType = 'info'
				} else {
					this.btnLoginDisabled = false,
					this.buttonType = 'primary'
				}
			},
			login() {
				let parmes = {
					username: this.account,
					password: this.password
				}
				AuthLoginByAccount(parmes).then(res => {
					console.log(res)
					if(res.errno == 0){
						this.userInfo = res.data.userInfo;
						setLocalStorage({
							Authorization: res.data.token,
							avatar: this.userInfo.avatarUrl,
							nickName: this.userInfo.nickName,
							pin: this.userInfo.pin,
							distNo: this.userInfo.distNo
						})
						this.$router.push({
							path: '/userInfo'
						})
					}else{
						this.$notify.error({
							title: res.errmsg
						})
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	@import '../../common/style';
	.container {
		margin-top: 80px;
		width: 97vw;
		height: 100vh;
		// background-color: red;
		display: flex;
		justify-content: center;
		align-items: center;

		.inside {
			height: 574px;
			width: 452px;
			//background-color: yellow;
			box-shadow: 5px 5px 5px #dadada;
			border: 1px solid #dadada;

			.header {
				width: 100%;
				height: 100px;
				// background-color: pink;
				flex-direction: column;
				display: flex;
				align-items: center;
				justify-content: center;
				.title {
					color: #606266;
					font-weight: 600;
					font-size: 25px;
					margin-top: 0px;
					margin-bottom: 0px;
				}
			}

			.contact {
				width: 100%;
				height: 370px;
				//background-color: orange;
				flex-direction: column;
				display: flex;
				align-items: center;

				.account_password {
					width: 90%;
					height: 150px;

					//background-color: green;
					.account_input {
						margin-bottom: 15px;

						input {
							height: 50px;
						}
					}

					.password_input {
						input {
							height: 50px;
						}
					}
				}

				.Remember_password {
					width: 90%;
					height: 30px;
					//background-color: greenyellow;
					display: flex;
					justify-content: space-between;
					padding-left: 500px;

					.zhuche {
						font-size: 14px;
						color: #333;
						padding: 1px 10px 0;
						cursor: pointer;
						font-weight: 600;
						a{
							border-bottom: 2px solid $neirongColor;
						}
						a:hover{
							background-color: $neirongColor;
						}
					}
				}

				.btns {
					width: 90%;
					height: 140px;
					//background-color: paleturquoise;
					margin-top: 20px;
					display: flex;
					flex-direction: column;

					.btn_login {
						width: 100%;
						height: 50px;
						font-size: 18px;
					}
					.el-button--primary{
						background-color: $neirongColor;
						border-color: $neirongColor;
					}
					.btn_return {
						width: 100%;
						height: 50px;
						font-size: 18px;
						margin-top: 20px;
						margin-left: 0px;
					}
					.btn_return:hover{
						background-color: $neirongColor;
						border-color: $neirongColor;
						color: white;
					}
				}
			}

			.foot {
				width: 100%;
				height: 60px;
				//background-color: mediumpurple;
				display: flex;
				justify-content: center;

				.foot_containser {
					width: 90%;
					height: 100%;
					//background-color: red;
					border-top: 1px solid #ccc;
					display: flex;
					align-items: center;

					span {
						font-size: 12px;
						color: #999;
					}
				}
			}
		}
	}
</style>
