import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/login.vue'

import userInfo from '../views/userInfo/userInfo.vue'
// 用户详情的子页面
import MyOrder from '../views/userInfo/childRouter/MyOrder.vue'
import AccountInformation from '../views/userInfo/childRouter/AccountInformation.vue'
import HarvestAddress from '../views/userInfo/childRouter/HarvestAddress.vue'
import orderDetail from '../views/userInfo/childRouter/orderDetail.vue'

import placeOrder from '../views/placeOrder/placeOrder.vue'
import Cart from '../views/cart/cart.vue'
import Home from '../views/home/home.vue'
import Detail from '../views/Detail/Detail.vue'
import List from '../views/List/List.vue'
import Register from '../views/register/register.vue'
import Forget from '../views/forget/forget.vue'
import Search from '../views/search/search.vue'
import Payment from '../views/payment/payment.vue'
import Paysuc from '../views/paySuc/paySuc.vue'
import Payfail from '../views/payFail/payFail.vue'
Vue.use(VueRouter)

//解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
	{
		path: '/',//主页
		component: Home
	},
	{
		path: '/userInfo',//个人中心页面
		component: userInfo,
		children: [
			{ path: '/userInfo', redirect: 'AccountInformation' },
			{
				path: 'AccountInformation',
				component: AccountInformation
			},
			{
				path: 'MyOrder',
				component: MyOrder
			},
			{
				path: 'HarvestAddress',
				component: HarvestAddress
			},
			{
				path: 'orderDetail',
				component: orderDetail
			}
		]
	},
	{
		path: '/placeOrder',//订单提交页面
		component: placeOrder
	},
	{
		path: '/Cart',//购物车页面
		component: Cart
	},
	{
		path: '/Login',//登录界面
		component: Login
	},
	{
		path: '/Detail',//全部商品页面
		component: Detail
	},
	{
		path: '/List',//商品详情页面
		component: List
	},
	{
		path: '/Register',//注册
		component: Register
	},
	{
		path:'/Forget',//忘记密码
		component: Forget
	},
	{
		path:'/Search',//搜索
		component: Search
	},
	{
		path:'/Payment',//订单内容
		component: Payment
	},
	{
		path:'/Paysuc',//支付成功
		component: Paysuc
	},
	{
		path:'/Payfail',//支付失败
		component: Payfail
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})


export default router
