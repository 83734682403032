<template>
  <div>
    <div class="black-header">
      <div class="sousuo">
        <el-input
          @keyup.enter.native="send"
          :placeholder="i18n.Pleaseentertheproductname"
          v-model="input"
        ></el-input>
        <el-button
          @click="send"
          style="height: 40px"
          slot="append"
          icon="el-icon-search"
        ></el-button>
      </div>

      
      <div class="middle">
        <div @click="gohome" class="box1">
          <img class="box1-img" src="../assets/logo22.png" alt="" />
        </div>
        <div
          :style="{ bottom: $route.path == '/Detail' ? '40px' : '65px' }"
          :class="[lang.language == 'en-US' ? 'box1-text2' : 'box1-text']"
        >
          <!-- <router-link to="/">{{i18n.homePage}}</router-link> -->
          <router-link to="/Detail">{{ i18n.Allproducts }}</router-link>
          <router-link to="/Cart">{{ i18n.ShoppingCart }}</router-link>
          <router-link to="/userInfo/AccountInformation">{{
            i18n.personalinformation
          }}</router-link>
          <el-dropdown @command="changelang" style="cursor: pointer">
            <span
              style="color: #000000; font-size: 15px"
              class="el-dropdown-link"
            >
              <img :src="lang.language == 'en-US' ? imgUrl2 : imgUrl" />
              {{ lang.language == "en-US" ? "English" : "繁體中文" }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-around;
                  width: 100px;
                "
                command="zh"
              >
                <img src="../assets/香港.png" />
                繁體中文
              </el-dropdown-item>
              <el-dropdown-item
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-around;
                  width: 85px;
                "
                command="en"
              >
                <img src="../assets/英国.png" />
                English
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-if="$route.path == '/Detail'" class="product-list">
          <div
            :class="{
              insideListColor: $store.state.insideListColorId == index,
            }"
            class="inside-list"
            v-for="(item, index) in productList"
            :key="item.id"
            @click="clickProduct(item.id, index)"
          >
            {{ lang.language == "en-US" ? item.usName : item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetL1 } from "../api/index.js";
import { setLocalStorage, getLocalStorage } from "@/utils/local-storage";
export default {
  name: "HelloWorld",
  created() {
    this.$store.state.insideListColorId = getLocalStorage(
      "insideListColorIndex"
    );
    this.lang = getLocalStorage("language");
    GetL1().then((res) => {
      this.productList = res.data;
    });
  },
  data() {
    return {
      imgUrl: require("../assets/香港.png"),
      imgUrl2: require("../assets/英国.png"),
      input: "",
      lang: "",
      productList: [],
      productIndex: 0,
    };
  },
  computed: {
    i18n() {
      return this.$t("index");
    },
  },
  methods: {
    changelang(command) {
      if (command == "zh") {
        location.reload();
        setLocalStorage({
          language: "zh-HK",
        });
        this._i18n.locale = "zh-HK";
        console.log("转化成繁体字");
      } else {
        location.reload();
        setLocalStorage({
          language: "en-US",
        });
        this._i18n.locale = "en-US";
        console.log("转化成Engilsh");
      }
    },
    send() {
      if (this.input == "") {
        this.$notify.error({
          title: this.i18n.Pleaseenterthecontent,
        });
      } else {
        let routeUrl = this.$router.resolve({
          path: "/Search",
          query: {
            input: this.input,
          },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    allShop() {
      this.$router.push({
        path: "/Detail",
      });
    },
    gohome() {
      this.$router.push({
        path: "/",
      });
    },
    zhanghu() {
      this.$router.push({
        path: "/userInfo",
      });
    },
    order() {
      this.$router.push({
        path: "/Cart",
      });
    },
    clickProduct(id, index) {
      this.$store.commit("change", index);
      this.$store.dispatch("clickProduct", id);
      setLocalStorage({
        insideListColorIndex: index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../common/style";

.chooseLang {
  height: 100%;
  width: 250px;
  background-color: red;
  position: relative;
  z-index: 6;
  display: flex;
  left: 1160px;
  top: 30px;
}

.sousuo {
  height: 50px;
  width: 400px;
  // background-color: red;
  position: relative;
  z-index: 6;
  display: flex;
  left: 150px;
  top: 40px;
}

.black-header {
  width: 100%;
  height: 145px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  background: #fff;
  display: flex;
  justify-content: center;
  box-shadow: 0px 5px 5px #f0f0f0;
  border: 1px solid #f0f0f0;

  .middle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    align-items: center;

    .box1 {
      position: relative;
      right: 700px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      .box1-img {
        margin-top: 20px;
        width: 150px;
        height: 76px;
      }

      .shopName {
        font-size: 20px;
        color: $neirongColor;
        //margin-top: 40px;
      }
    }

    .product-list {
      width: 600px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      //background-color: red;
      height: 30px;
      position: relative;
      right: 210px;

      .inside-list {
        cursor: pointer;
      }

      .insideListColor {
        color: $neirongColor;
      }
    }

    .box1-text {
      width: 350px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      position: relative;
      bottom: 40px;
      left: 300px;

      a {
        text-decoration: none;
        color: #000000;
      }

      .router-link-exact-active {
        color: $neirongColor;
      }

      a:hover {
        color: $neirongColor;
        cursor: pointer;
      }
    }

    .box1-text2 {
      width: 470px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      position: relative;
      bottom: 40px;
      left: 250px;

      a {
        text-decoration: none;
        color: #000000;
      }

      .router-link-exact-active {
        color: $neirongColor;
      }

      a:hover {
        color: $neirongColor;
        cursor: pointer;
      }
    }
  }
}
</style>
